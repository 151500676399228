import React from "react";
import {SharedTableHeadAttributes} from "@/components/ProgressList/results/SharedTableHead";
import {SharedTableBodyAttributes} from "@/components/ProgressList/results/SharedTableBody";
import {SortIndicator} from "@/components/SortIndicator";
import * as Config from "@/config";


export const BasicMapTableHead = () => {
  return (
    <>
      <SharedTableHeadAttributes/>
      {/* 基盤情報ステータス */}
      <th rowSpan={2} style={{minWidth: 100, maxWidth: 100}} className="sort-indicator-wrap">
        <div className="border-box"/>
        作成会社<br/>ステータス
        <SortIndicator item_name={Config.ProgressListSortItem.SUBMISSION_STATUS}/>
      </th>
      <th rowSpan={2} style={{minWidth: 100, maxWidth: 100}} className="sort-indicator-wrap">
        <div className="border-box"/>
        検定機関<br/>ステータス
        <SortIndicator item_name={Config.ProgressListSortItem.CERTIFICATION_STATUS}/>
      </th>
    </>
  )
}

interface BasicMapTableBodyProps {
  data: any;
  checked: boolean;
  basicMapStatus: { certification: string | undefined, submission: string | undefined };
}

export const BasicMapTableBody = (props: BasicMapTableBodyProps) => {
  return (
    <>
      <SharedTableBodyAttributes data={props.data} checked={props.checked}/>
      {/* 基盤地図ステータス */}
      <td data-tooltip-id="progress-tooltip" data-tooltip-html={props.basicMapStatus.submission}>
        <div className="border-box"/>
        {props.basicMapStatus.submission}
      </td>
      <td data-tooltip-id="progress-tooltip" data-tooltip-html={props.basicMapStatus.certification}>
        <div className="border-box"/>
        {props.basicMapStatus.certification}
      </td>
    </>
  )
}