import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import { useLoadingStore } from "@/stores/loading";
import {MESSAGE_NO_E09, MESSAGE_NO_E37, MESSAGE_NO_E39, PERMISSION_ROLL_SYSADMIN} from "@/config";
import { useUserStore } from "@/stores/user";
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import {
  ApproverGroup,
  ApproverMember,
  ApproverPattern,
  Department,
  OfficialPosition,
  UserOfficialPosition
} from '@/api/approverManagement'
import {Control, FormProvider, useFieldArray, useForm, useFormContext, useWatch} from 'react-hook-form'
import * as Common from '@/utils/common'
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

interface LastUpdated {
  last_updated_by?: string,
  last_updated_at?: string,
}

interface ApproverPatternForm {
  approver_patterns: ApproverPattern[],
}

interface Modal {
  message: string,
  label: string,
  callback: () => void,
}

export default function ApproverPatternList() {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_DECISION_MANAGEMENT);

  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);

  const [selectDepartment, setSelectDepartment] = useState<string>("-");
  const [approverPatterns, setApproverPatterns] = useState<ApproverPattern[]>([]);
  const [officialPositions, setOfficialPositions] = useState<OfficialPosition[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [approverGroups, setApproverGroups] = useState<ApproverGroup[]>([]);
  const [userOfficialPositions, setUserOfficialPositions] = useState<UserOfficialPosition[]>([]);

  const [checkIds, setCheckIds] = useState(new Set<number>());
  const [isNew, setNew] = useState<boolean>(false);
  const isCopy = !isNew && checkIds.size === 1;

  const [modalConfirm, setModalConfirm] = useState<Modal | undefined>(undefined);
  // const [lastUpdated, setLastUpdated] = useState<LastUpdated>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const methods = useForm<ApproverPatternForm>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      // values: {
      //   approver_patterns: approverPatterns,
      // },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors }
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "approver_patterns",
    keyName: "key",
  });

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: '決済社一覧' }
  ];

  function getList() {
    setLoading(true)
    const requests = [
      axios.get("/api/v1/approverManagement/getOfficialPositions"),
      axios.get("/api/v1/approverManagement/getDepartments"),
      axios.get("/api/v1/approverManagement/getApproverPatterns"),
      axios.get("/api/v1/approverManagement/getApproverGroups"),
      axios.get("/api/v1/approverManagement/getUserOfficialPositions"),
    ];

    Promise.all(requests).then(( response ) => {
      setOfficialPositions(response[0].data.official_positions);
      setDepartments(response[1].data.departments);
      setApproverPatterns(response[2].data.approver_patterns);
      setApproverGroups(response[3].data.approver_groups);
      setUserOfficialPositions(response[4].data.user_official_positions);

      reset({
        approver_patterns: response[2].data.approver_patterns,
      });
      setNew(false);
      setCheckIds(new Set(checkIds));
    }).catch(error => {
      setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getList();
  }, []);

  const onChangeDeparts = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectDepartment(selectedValue);
    setCheckIds(new Set(checkIds));
    const filteredApproverPatterns = selectedValue == "-"
      ? approverPatterns : approverPatterns.filter(value => (value.department_id?.toString() == selectedValue));
    console.log("selectedValue: ", selectedValue, "filteredApproverPatterns:" ,filteredApproverPatterns)
    reset({
      approver_patterns: filteredApproverPatterns,
    });
  }

  const onClickCancel = () => {
    setModalConfirm({
      message: MESSAGE_NO_E09,
      label: "OK",
      callback: () => {
        navigate(-1);
      }
    });
  }

  const onClickSave = () => {
    const postData = getValues();
    setLoading(true);
    axios.post("/api/v1/approverManagement/postApproverPatterns", {
      post_data: postData.approver_patterns
    }).then(result => {
      getList();
      return true;
    }).catch(error => {
      if (error?.response?.data?.message) {
        console.log(error?.response?.data?.message)
        const message = error.response.data.message;
        if (Array.isArray(message)) {
          message.forEach((v: any, index: number) => {
            for (const [key, value] of Object.entries(v)) {
              const typeKey = key as keyof ApproverPattern;
              const typeVaule = value as string;
              setError(`approver_patterns.${index}.${typeKey}`, {
                type: "server", message: typeVaule
              });
            }
          });
        } else if (typeof message === 'object' && message !== null) {
          for (const [index, v] of Object.entries(message) as [number, any]) {
            for (const [key, value] of Object.entries(v)) {
              const typeKey = key as keyof ApproverPattern;
              const typeVaule = value as string;
              setError(`approver_patterns.${index}.${typeKey}`, {
                type: "server", message: typeVaule
              });
            }
          }
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
      } else {
        setErrorMessage(Config.MESSAGE_NO_E37);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const onClickCopy = () => {
    setNew(true);
    const approverPattern = approverPatterns[Array.from(checkIds)[0]];
    delete approverPattern['id'];
    for (const approver_member of approverPattern.approver_members) {
      delete approver_member['id'];
    }
    append(approverPattern);
  }

  const onClickNew = () => {
    setNew(true);
    append({
      approver_members: [{}]
    });
  }

  const onClickCheck = (id: number) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="役職管理"/>
        {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}
        {/*<div className="uk-text-right uk-margin-small-bottom">*/}
        {/*  最終更新ユーザ：{lastUpdated?.last_updated_by} 最終更新:{lastUpdated?.last_updated_at}*/}
        {/*</div>*/}

        <div className="uk-flex-between uk-flex uk-flex-middle mb-40">
          <div className="approver-pattern-container uk-width-1-2">
            <div className="uk-margin-right approver-pattern-tbl">
              <table className="uk-width-1-1">
                <tbody>
                <tr>
                  <th className="label">
                    部署名
                  </th>
                  <td className="content">
                    <select
                      className="select-department" defaultValue={"-"}
                      onChange={onChangeDeparts}
                      disabled={isNew}
                    >
                      <option value="-">-</option>
                      {departments.map(( item, index ) => (
                        <option key={`department-${index}`} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="">
            <button className="uk-button--m uk-button-cancel" onClick={onClickCancel}>キャンセル</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickCopy} disabled={!isCopy}>複製</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
          </div>
        </div>
      </div>

      <div>
        <FormProvider {...methods}>
          {fields.map((approver_pattern, index) => (
            <ApproverPatternContainer
              key={approver_pattern.key}
              onClickCheck={onClickCheck}
              checkIds={checkIds}
              departments={departments}
              approver_groups={approverGroups}
              user_official_positions={userOfficialPositions}
              approver_pattern={approver_pattern}
              approver_pattern_index={index}
              setModal={setModalConfirm}
            />
          ))}
        </FormProvider>
      </div>
      <ConfirmModal
        text={modalConfirm?.message ?? ""}
        confirmButtonText={modalConfirm?.label ?? ""}
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if(modalConfirm)modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={errorMessage != null}
              onConfirm={() => {
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}

interface ApproverPatternProps {
  onClickCheck: (id: number) => void,
  checkIds: Set<number>,
  departments: Department[],
  approver_groups: ApproverGroup[],
  user_official_positions: UserOfficialPosition[],
  approver_pattern: ApproverPattern,
  approver_pattern_index: number,
  setModal: React.Dispatch<React.SetStateAction<Modal | undefined>>,
}
const ApproverPatternContainer = (props: ApproverPatternProps) => {
  const {
    onClickCheck,
    checkIds,
    departments,
    approver_groups,
    user_official_positions,
    approver_pattern,
    approver_pattern_index,
    setModal
  } = props;
  const { register, control, formState: {errors} } = useFormContext<ApproverPatternForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `approver_patterns.${approver_pattern_index}.approver_members`,
    keyName: "key",
  });

  const onClickAdd = () => {
    append({});
  }

  // console.log("approver_pattern: ", approver_pattern)

  return (
    <>
      <div className="approver-pattern-container-checkbox-padding approver-pattern-container mb-40">
        <div className="uk-flex uk-flex-right checkbox-container">
          {approver_pattern.id &&
              <input type="checkbox" onChange={() => {onClickCheck(approver_pattern_index)}} checked={checkIds.has(approver_pattern_index)}/>
          }
        </div>
        <div className="approver-pattern-tbl uk-width-1-1">
          <table className="uk-width-1-1">
            <tbody>
            <tr>
              <th className="label">
                部署名
              </th>
              <td className="content" colSpan={2}>
                {approver_pattern.id ?
                  <>{departments.find(value => value.id === approver_pattern.department_id)?.name}</>
                  :
                  <select
                    className="uk-width-1-1"
                    {...register(`approver_patterns.${approver_pattern_index}.department_id`)}
                  >
                    {departments.map(( item, index ) => (
                      <option key={`department-${index}`} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                }
                {errors.approver_patterns?.[approver_pattern_index]?.department_id &&
                    <p className="precaution">
                      {errors.approver_patterns?.[approver_pattern_index]?.department_id?.message}
                    </p>
                }
              </td>
            </tr>
            <tr>
              <th className="label">
                決済パターン名
              </th>
              <td className="content" colSpan={2}>
                <input
                  type="text"
                  className={"uk-width-1-1"}
                  {...register(`approver_patterns.${approver_pattern_index}.name`)}
                />
                {errors.approver_patterns?.[approver_pattern_index]?.name &&
                    <p className="precaution">
                      {errors.approver_patterns?.[approver_pattern_index]?.name?.message}
                    </p>
                }
              </td>
            </tr>
            <tr>
              <th className="label clear-border-right">
              </th>
              <th className="label clear-border-side">
                決済者
              </th>
              <th className="label clear-border-left">
                <div>
                  <label className="inline-label">
                    <input type="checkbox" {...register(`approver_patterns.${approver_pattern_index}.stop_use_flag`)} value={1}/>
                    利用停止
                  </label>
                </div>
              </th>
            </tr>
            {fields.map((value, member_index) => (
              <ApproverMemberContainer key={value.key} {...props} member_index={member_index} remove={remove}/>
            ))}
            </tbody>
          </table>
          <button className="uk-button--m uk-button-refer uk-margin-small-top" onClick={onClickAdd}>追加</button>
        </div>
      </div>
    </>
  )
}

interface ApproverMemberProps extends ApproverPatternProps{
  member_index: number,
  remove: (index?: number | number[]) => void,
}
const ApproverMemberContainer = (props: ApproverMemberProps) => {
  const { departments, approver_groups, user_official_positions, approver_pattern, approver_pattern_index, member_index, setModal, remove } = props;
  const { register, control, formState: {errors} } = useFormContext<ApproverPatternForm>();

  const selectedUserId = useWatch({
    control,
    name: `approver_patterns.${approver_pattern_index}.approver_members.${member_index}.user_id`,
  });

  const onClickDelete = (index: number) => {
    setModal({
      message: "削除しますか？",
      label: "削除",
      callback: () => {
        console.log("delete", `approver_patterns.${approver_pattern_index}.approver_members.${index}`)
        remove(index);
      }
    });
  }

  return (
    <>
      <tr>
        <th className="label">
          {user_official_positions.find(value => (value.user_id == selectedUserId))?.official_position_name}
        </th>
        <td className="content clear-border-right">
          <select
            className="uk-width-1-1"
            {...register(`approver_patterns.${approver_pattern_index}.approver_members.${member_index}.user_id`)}
          >
            {user_official_positions.map(( item, index ) => (
              <option key={`department-${index}`} value={item.user_id}>{item.user_name}</option>
            ))}
          </select>
        </td>
        <td className="content clear-border-left">
          <div className="uk-flex uk-flex-middle">
            <select
              className="uk-width-1-1"
              {...register(`approver_patterns.${approver_pattern_index}.approver_members.${member_index}.approver_group_id`)}
            >
              {approver_groups.map(( item, index ) => (
                <option key={`approver_group-${index}`} value={item.id}>{item.name}</option>
              ))}
            </select>
            <button className="close-button" onClick={() => {onClickDelete(member_index)}}>&times;</button>
          </div>
        </td>
      </tr>
    </>
  )
}