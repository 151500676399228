import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from "@/components/ConfirmModal";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import DicDataImport from './DicDataImport'
import {DicDataExport} from '@/containers/admin/Dic/DicDataExport'
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

export default function DicDataManagement() {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_DIC_DATA_MANAGEMENT);

  const [selectedTab, setSelectedTab] = useState("")
  const [modalMessage, setModalMessage] = useState<{ type: {}, message: string } | null>(null)

  const breadCrumbsTitle = (value: string) => {
    switch (value) {
      case "import":
        setSelectedTab("インポート")
        break
      case "export":
        setSelectedTab("エクスポート")
        break
      default:
        break
    }
  }

  const breadCrumbs = [
    {title: '管理画面', path: '/admin'},
    {title: selectedTab}
  ];

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="DICデータ管理"/>
      </div>
      <div className="container">
        <ul className="uk-tab uk-margin-small-top" data-uk-tab="{connect:'#data-tab'}">

          <li onClick={() => breadCrumbsTitle("import")} className="uk-active uk-data">
            <Link to="/admin/data">インポート</Link>
          </li>


          <li onClick={() => breadCrumbsTitle("export")} className="uk-active uk-data">
            <Link to="/admin/data">エクスポート</Link>
          </li>

        </ul>
        <ul id="data-tab" className="uk-switcher">
          <li><DicDataImport/></li>
          <li><DicDataExport setModalMessage={setModalMessage}/></li>
        </ul>
      </div>
      <ConfirmModal
        text={modalMessage?.message ?? ""}
        confirmButtonText="OK"
        isShow={!!modalMessage?.message}
        onConfirm={() => setModalMessage(null)}
      />
    </div>
  );
}