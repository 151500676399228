import {
  PostInformationParams,
  getInformation,
  postInformation, Information,
} from "@/api/information";
import ConfirmModal from "@/components/ConfirmModal";
import { CheckBox } from "@/components/Form/CheckBox";
import { TextArea } from "@/components/Form/TextArea";
import { TextBox } from "@/components/Form/TextBox";
import {MESSAGE_NO_E08, MESSAGE_NO_E35, MESSAGE_NO_E37, MESSAGE_NO_E39} from "@/config";
import { useLoadingStore } from "@/stores/loading";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubHeader from "@/components/SubHeader";
import {SelectBox} from '@/components/Form/SelectBox'
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import {RadioButton} from '@/components/Form/RadioButton'
import * as Common from '@/utils/common'
import remarkGfm from 'remark-gfm'
import {CustomMarkdown} from "@/components/CustomMarkdown";
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

interface Form {
  title: string;
  body: string;
  drm_pf_information_type_id: string;
  drm_pf_information_status_id: string;
  release_date: string;
}

const DrmPfInformationView: FC = () => {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_PORTAL_MANAGEMENT);

  const location = useLocation();
  const navigate = useNavigate();
  const informationId = useParams().id;
  const setLoading = useLoadingStore((state) => state.setLoading);
  const isDuplicate = location.pathname.includes("/duplicate");

  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [drmPfInformationStatuses, setDrmPfInformationStatuses] = useState<any[]>([]);
  const [drmPfInformationTypes, setDrmPfInformationTypes] = useState<any[]>([]);
  const [lastInformation, setLastInformation] = useState<any | undefined>(undefined);

  const [formData, setFormData] = useState<Form>();

  function getFormInputs() {
    setLoading(true);
    axios.get('/api/v1/drmPfInformation/formInputs').then(result => {
      setDrmPfInformationStatuses(result.data.drmPfInformationStatuses);
      setDrmPfInformationTypes(result.data.drmPfInformationTypes);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (drmPfInformationStatuses.length === 0 &&  drmPfInformationTypes.length === 0) {
      getFormInputs();
    }
  }, []);

  useEffect(() => {
    if (!informationId) return;

    setLoading(true);

    axios.get("/api/v1/drmPfInformation/view", {
      params: { id: Number(informationId) },
    }).then((res) => {
        const drmPfInformation = res.data.drmPfInformation;
        setFormData({
          title: drmPfInformation.title,
          body: drmPfInformation.body,
          drm_pf_information_type_id: String(drmPfInformation.drm_pf_information_type_id),
          drm_pf_information_status_id: drmPfInformation.drm_pf_information_status_id,
          release_date: drmPfInformation.release_date,
        });
        setLastInformation({
          modified_by: `${drmPfInformation.modified_user.last_name} ${drmPfInformation.modified_user.first_name}`,
          modified: drmPfInformation.modified,
        })
      })
      .catch(() => {
        setErrorMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [informationId, isDuplicate, setLoading]);

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "お知らせ一覧", path: "/admin/portal" },
    { title: "閲覧" },
  ];

  const onClickEdit = () => {
    navigate(`/admin/portal/${informationId}/edit`);
  };

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs}
                   title={informationId && !isDuplicate ? `${informationId.padStart(5, '0')}` : "お知らせ管理"} />
        <div className="uk-flex-right uk-flex uk-flex-middle">
          <div>
            <button
              className="uk-button--m uk-button-refer uk-margin-left"
              onClick={onClickEdit}
            >
              編集
            </button>
          </div>
        </div>
      </div>
      <div className="container">
          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-2">
              <tbody>
              <tr className="drm-pf-information-admin-tbl-header">
                <th className="label">
                  ステータス
                </th>
                <td className="content">
                  {drmPfInformationStatuses.find((value: any) =>
                    value.id == formData?.drm_pf_information_status_id)?.name}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
                <tr className="drm-pf-information-admin-tbl-header">
                  <th>
                    情報区分
                  </th>
                  <td>
                    <div className="uk-flex">
                      {drmPfInformationTypes.find((value: any) =>
                        value.id == formData?.drm_pf_information_type_id)?.name}
                    </div>
                  </td>

                  <th>
                    公開日
                  </th>
                  <td>
                    <div className="uk-flex">
                      {formData?.release_date}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
                <tr className="drm-pf-information-admin-tbl-header">
                  <th>
                    管理番号
                  </th>
                  <td>
                    <div className="width-40p uk-flex">
                      {informationId?.padStart(5, '0')}
                    </div>
                  </td>
                </tr>
                <tr className="drm-pf-information-admin-tbl-header">
                  <th>
                    公開タイトル
                  </th>
                  <td>
                    <div className="uk-width-1-1 uk-flex">
                      {formData?.title}
                    </div>
                  </td>
                </tr>
                <tr className="drm-pf-information-admin-tbl-header">
                  <th>
                    公開内容
                  </th>
                  <td>
                    <div className="description uk-width-1-1">
                      <CustomMarkdown content={formData?.body}/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        {lastInformation &&
            <div className="uk-text-right uk-margin-small-top uk-margin-small-bottom">
                最終更新ユーザ: {lastInformation?.modified_by} 最終更新:{" "}
              {moment(lastInformation?.modified).format("YYYY-MM-DD HH:mm")}
            </div>
        }
      </div>

      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
};

export default DrmPfInformationView;
