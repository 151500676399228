import React from "react";
import {Attribute} from "@/components/ProgressList/types";
import {SharedTableHeadAttributes, SharedTableHeadTabNames} from "@/components/ProgressList/results/SharedTableHead";
import * as Config from "@/config";
import {SharedTableBodyAttributes, SharedTableBodyTabs} from "@/components/ProgressList/results/SharedTableBody";
import moment from "moment";
import * as Common from "@/utils/common";
import {SortIndicator} from '@/components/SortIndicator'

interface ApprovalTableHeadProps {
  desiredDeliveryDateAttr: Attribute[];
  approverMembers: any[];
  officialPositions: any[];
}

export const ApprovalTableHead = (props: ApprovalTableHeadProps) => {
  return (
    <>
      <th rowSpan={2}>
        <div className="border-box"/>
        申請状況
        <SortIndicator item_name={Config.ProgressListSortItem.REQUEST_STATUS}/>
      </th>
      <th rowSpan={2}>
        <div className="border-box"/>
        承認状況
        <SortIndicator item_name={Config.ProgressListSortItem.DECISION_STATUS}/>
      </th>
      <th rowSpan={2}>
        <div className="border-box"/>
        作成者
      </th>
      {/* 承認者(日付) */}
      {props.officialPositions?.filter((officialPosition: any) => officialPosition.regist_flag === true).map((officialPosition) => {
        return (
          <th rowSpan={2} key={`th_decision_user_${officialPosition.id}`} className="sort-indicator-wrap">
            <div className="border-box"/>
            {officialPosition.name}
            <SortIndicator item_name={Config.ProgressListSortItem.DECISION_USER} position_id={officialPosition.id}/>
          </th>
        );
      })}
    </>
  )

}

interface ApprovalTableBodyProps {
  checked: boolean;
  data: any;
  desiredDeliveryDateAttr: Attribute[];
  approverMembers: any[];
  officialPositions: any[];
}

export const ApprovalTableBody = (props: ApprovalTableBodyProps) => {
  const applyAreas = props.data.data_applies.map((apply: any) => apply.data_apply_products.map((applyProduct: any) => applyProduct.sales_product.name)).flat(1);
  const requestStatus = props.data.data_approval_requests[0]?.approval_request_status_id === 2 ? '申請済み' : '未申請'
  const decisionStatus = Common.decisionStatus(props.data);
  const shapedApplyAreas = applyAreas.filter(function (item: any) {
    return item !== 'サンプル';
  });
  const officialPositions = props.officialPositions?.filter((officialPosition: any) => officialPosition.regist_flag === true);
  const createdUser = props.data.data_approval_requests[0]?.create_user;

  return (
    <>
      {/* 申請状況&承認状況 */}
      <td style={{minWidth: 60}} className={props.checked ? "selected" : ""}
          data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
        <div className="border-box"/>
        {requestStatus}
      </td>
      <td style={{minWidth: 60}} className={props.checked ? "selected" : ""}
          data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
        <div className="border-box"/>
        {decisionStatus}
      </td>
      <td style={{minWidth: 60}} className={props.checked ? "selected" : ""}
          data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
        <div className="border-box"/>
        {createdUser ? `${createdUser.last_name}${createdUser.first_name}` : "-"}
      </td>
      {/* 承認者(日付) */}
      {officialPositions.map((officialPosition) => {
        const decisionUser = props.approverMembers.find((approverMember) =>
          approverMember?.user_official_positions[0]?.official_position_id === officialPosition?.id
        );
        const style = {
          minWidth: 80,
          maxWidth: 80,
        }
        const key = `td_decision_user_${props.data.id}_${officialPosition.id}`;
        const approvalRequests = props.data.data_approval_requests;
        let content = '-';
        if (decisionUser && approvalRequests.length > 0) {
          const me = approvalRequests[0].data_approval_decision_users.find((u: any) =>
            u.approver_member_id == decisionUser.id);
          if (me) {
            if (me.approval_decision_status.code === Config.APPROVAL_DECISION_STATUS_APPROVED_CODE) {
              content = `${moment(me.modified).format('YYYY/MM/DD')}\n${me.comments ?? ''}`;
            } else {
              content = me.comments ?? '';
            }
          }
        }
        return (
          <td
            style={style}
            key={key}
            data-tooltip-id="progress-tooltip" data-tooltip-html={content.replaceAll('\n', '<br/>')}>
            <div className="border-box"/>
            {content}
          </td>
        )

      })}
    </>
  )
}