import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import { useLoadingStore } from "@/stores/loading";
import { MESSAGE_NO_E37, MESSAGE_NO_E39, PERMISSION_ROLL_SYSADMIN } from "@/config";
import { useUserStore } from "@/stores/user";
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import {Department, OfficialPosition, UserOfficialPosition} from '@/api/approverManagement'
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

interface LastUpdated {
  last_updated_by?: string,
  last_updated_at?: string,
}

export default function PositionList() {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_POSITION_MANAGEMENT);

  const navigate = useNavigate();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);

  const [officialPositions, setOfficialPositions] = useState<OfficialPosition[]>([]);
  const [addOfficialPosition, setAddOfficialPosition] = useState<string>("");
  const [isAddOfficialPosition, setIsAddOfficialPosition] = useState<boolean>(false);

  const [departments, setDepartments] = useState<Department[]>([]);
  const [addDepartment, setAddDepartment] = useState<string>("");
  const [isAddDepartment, setIsAddDepartment] = useState<boolean>(false);

  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // const [lastUpdated, setLastUpdated] = useState<LastUpdated>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: '決済社一覧' }
  ];

  function getList() {
    setLoading(true)
    const requests = [
      axios.get("/api/v1/approverManagement/getOfficialPositions"),
      axios.get("/api/v1/approverManagement/getDepartments"),
    ];

    Promise.all(requests).then(( response ) => {
      setOfficialPositions(response[0].data.official_positions);
      setDepartments(response[1].data.departments);
    }).catch(error => {
      setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getList();
  }, [])

  const onClickNewOfficialPosition = () => {
    setIsAddOfficialPosition(true);
  }

  const onClickAddOfficialPosition = () => {
    setLoading(true);
    axios.post("/api/v1/approverManagement/postOfficialPosition", {
      name: addOfficialPosition
    }).then(result => {
      setIsAddOfficialPosition(false);
      setAddOfficialPosition("");
      getList();
      return true;
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E37);
    }).finally(() => {
      setLoading(false);
    });
  }

  const openDeleteOfficialPositionModal = ( id: number ) => {
    setModalConfirm({callback: () => {
        setLoading(true);
        axios.post("/api/v1/approverManagement/deleteOfficialPosition", {
          id: id
        }).then(result => {
          setModalConfirm(undefined);
          getList();
          return true;
        }).catch(error => {
          setErrorMessage(MESSAGE_NO_E37);
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  }

  const onClickNewDepartment = () => {
    setIsAddDepartment(true);
  }

  const onClickAddDepartment = () => {
    setLoading(true);
    axios.post("/api/v1/approverManagement/postDepartment", {
      name: addDepartment
    }).then(result => {
      setIsAddDepartment(false);
      setAddDepartment("");
      getList();
      return true;
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E37);
    }).finally(() => {
      setLoading(false);
    });
  }

  const openDeleteDepartmentModal = ( id: number ) => {
    setModalConfirm({callback: () => {
        setLoading(true);
        axios.post("/api/v1/approverManagement/deleteDepartment", {
          id: id
        }).then(result => {
          setModalConfirm(undefined);
          getList();
          return true;
        }).catch(error => {
          setErrorMessage(MESSAGE_NO_E37);
        }).finally(() => {
          setLoading(false);
        });
      }
    });
  }

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="役職管理"/>
        {/*<div className="uk-text-right uk-margin-small-bottom">*/}
        {/*  最終更新ユーザ：{lastUpdated?.last_updated_by} 最終更新:{lastUpdated?.last_updated_at}*/}
        {/*</div>*/}
      </div>
      <div className="container position-container uk-flex">
        <div className="position-half">
          <div className="position-button-container">
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNewOfficialPosition}>
              役職新規作成
            </button>
          </div>
          <div className="position-tbl-wrap">
            <table className="position-tbl">
              <thead>
              <tr>
                <th>
                  <div className="border-box"/>
                  No.
                </th>
                <th className="uk-text-left">
                  <div className="border-box"/>
                  役職
                </th>
                <th>
                  <div className="border-box"/>
                  削除
                </th>
              </tr>
              </thead>
              <tbody>
              {officialPositions.map(( item, index ) => (
                <tr key={index}>
                  <td className="uk-text-center">
                    <div className="border-box"/>
                    {index + 1}</td>
                  <td className="uk-text-left">
                    <div className="border-box"/>{item.name}
                  </td>
                  <td className="uk-text-center">
                    <div className="border-box"/>
                    {item.regist_flag ?
                      "削除"
                      :
                      <a onClick={() => {
                        openDeleteOfficialPositionModal(item.id)
                      }}>削除</a>
                    }
                  </td>
                </tr>
              ))}
              {isAddOfficialPosition &&
                  <tr key={officialPositions.length}>
                      <td className="uk-text-center">
                          <div className="border-box"/>
                        {officialPositions.length + 1}</td>
                      <td className="uk-text-left">
                          <div className="border-box"/>
                          <input className={"uk-width-1-1"} type={'text'} value={addOfficialPosition} onChange={e => setAddOfficialPosition(e.target.value)}/>
                      </td>
                      <td className="uk-text-center">
                          <div className="border-box"/>
                          <button
                              className="uk-button--m uk-button-refer add-button"
                              onClick={onClickAddOfficialPosition}
                          >
                              追加
                          </button>
                      </td>
                  </tr>
              }
              </tbody>
            </table>
          </div>
        </div>


        <div className="position-half">
          <div className="position-button-container">
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNewDepartment}>
              部署新規作成
            </button>
          </div>
          <div className="position-tbl-wrap">
            <table className="position-tbl">
              <thead>
              <tr>
                <th>
                  <div className="border-box"/>
                  No.
                </th>
                <th className="uk-text-left">
                  <div className="border-box"/>
                  部署
                </th>
                <th>
                  <div className="border-box"/>
                  削除
                </th>
              </tr>
              </thead>
              <tbody>
              {departments.map(( item, index ) => (
                <tr key={index}>
                  <td className="uk-text-center">
                    <div className="border-box"/>
                    {index + 1}</td>
                  <td className="uk-text-left">
                    <div className="border-box"/>{item.name}
                  </td>
                  <td className="uk-text-center">
                    <div className="border-box"/>
                    {item.regist_flag ?
                      "削除"
                      :
                      <a onClick={() => {
                        openDeleteDepartmentModal(item.id)
                      }}>削除</a>
                    }
                  </td>
                </tr>
              ))}
              {isAddDepartment &&
                  <tr key={departments.length}>
                      <td className="uk-text-center">
                          <div className="border-box"/>
                        {departments.length + 1}</td>
                      <td className="uk-text-left">
                          <div className="border-box"/>
                          <input className={"uk-width-1-1"} type={'text'} value={addDepartment} onChange={e => setAddDepartment(e.target.value)}/>
                      </td>
                      <td className="uk-text-center">
                          <div className="border-box"/>
                          <button
                              className="uk-button--m uk-button-refer add-button"
                              onClick={onClickAddDepartment}
                          >
                              追加
                          </button>
                      </td>
                  </tr>
              }
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <ConfirmModal
        text="本当に削除しますか？登録済みの決済パターンも削除されます。"
        confirmButtonText="削除"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if(modalConfirm)modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}