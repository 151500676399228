import { axios } from "@/lib/axios";
import {TicketDestinations} from "@/components/Ticket/types";

export const getTicketDestinations = () => {
  return axios.get("/api/v1/ticketDestination/getAll");
};

export const addTicketDestination = (ticketDestination: TicketDestinations) => {
  return axios.post("/api/v1/ticketDestination/add", ticketDestination);
};

export const removeTicketDestination = (
  ticketDestination: TicketDestinations
) => {
  return axios.delete("/api/v1/ticketDestination/delete", {
    params: {
      id: ticketDestination.id,
    },
  });
};

export const getTicketDestinationBy = (project_id: number) => {
  return axios.get("/api/v1/ticketDestination/getByProjectId", {
    params: {
      project_id,
    },
  });
};
