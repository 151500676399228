import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useLocation, useNavigate, useParams } from "react-router-dom";
import { useLoadingStore } from '@/stores/loading';
import { useUserStore, User } from '@/stores/user'
import * as API from '@/api/approval';
import * as Common from '@/utils/common';
import * as Config from '@/config';
import {ApproveForm, DataApprovalDecisionUser} from './types';
import {FieldArrayWithId, FormProvider, useFieldArray, useForm, useWatch} from "react-hook-form";
import UserSearchBar from '@/components/UserSearchBar';
import moment from "moment";
import { useBlockerStore } from "@/stores/blocker";
import BlockModal from "@/components/BlockModal";
import ConfirmModal from "@/components/ConfirmModal";
import {axios} from '@/lib/axios'
import {ApproverMember, ApproverPattern, Department} from '@/api/approverManagement'
import {PROJECT_CATEGORY_ID_APPROVAL} from '@/config'
import {DataProps} from '@/containers/data/Data'

export default function ApproveEdit(props: DataProps) {
  const headerTop = (document.querySelector("header")?.offsetHeight ?? 0)
    + (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const navigate = useNavigate();
  const params = useParams();
  const setLoading = useLoadingStore(state => state.setLoading);
  const user = useUserStore(state => state.user);
  const location = useLocation();
  const setBlocker = useBlockerStore(state => state.setBlocker);

  const [isSetup, setIsSetup] = useState<boolean>(false);

  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<number>();
  const [approverPatterns, setApproverPatterns] = useState<ApproverPattern[]>([]);
  const [approverMembers, setApproverMembers] = useState<ApproverMember[]>([]);

  const [data, setData] = useState<any | null>();
  const [dataApprovalRequest, setDataApprovalRequest] = useState<any | null>(null);

  const [createUser, setCreateUser] = useState<User>();
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isCategoryApproval = params?.projectCategoryId == PROJECT_CATEGORY_ID_APPROVAL.toString();

  const isApplied = useMemo(() => {
    return dataApprovalRequest?.approval_request_status_id === Config.APPROVAL_REQUEST_STATUS_APPLIED_ID;
  }, [dataApprovalRequest]);

  // 作成者 or 起案者
  const isCreator = useMemo(() => {
    if (dataApprovalRequest && user) {
      return dataApprovalRequest.create_user_id === user.id || dataApprovalRequest.draft_user_id == user.id;
    }
    return !data;
  }, [dataApprovalRequest, user]);

  // システム管理者 or 決済管理権限
  const isAdmin = useMemo(() => {
    if (dataApprovalRequest && user) {
      return (
        user.roll === Config.PERMISSION_ROLL_SYSADMIN ||
        (user.user_restrictions.find(
          (restriction) =>
            restriction.category_restriction_code ===
              Config.RESTRICTION_HISTORY_APPROVE &&
            restriction.category_restriction_value ===
              Config.RESTRICTION_HISTORY_APPROVE_ADMIN.value &&
            String(restriction.project_category_id) === params.projectCategoryId
        )
          ? true
          : false)
      );
    }
    return false;
  }, [dataApprovalRequest, user]);

  const methods = useForm<ApproveForm>();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    reset,
    formState: { errors }
  } = methods;

  const { fields: memberFields, append, remove } = useFieldArray({
    control,
    name: "data_approval_decision_users",
    keyName: "key",
  });

  useBeforeUnload(useCallback(() => {
    if (params.dataId) navigator.sendBeacon(`${Config.API_URL}/api/v1/datas/unlock/${params.dataId}`);
  }, []));

  useEffect(() => {
    setBlocker(true);
    fetchDataApprovalRequest();
  }, [location.pathname]);

  useEffect(() => {
    if (isSetup && dataApprovalRequest && departments.length > 0 && approverPatterns.length > 0) {
      setIsSetup(false);
      reset(dataApprovalRequest);
    }
  }, [isSetup, dataApprovalRequest, departments, approverPatterns]);

  async function fetchDataApprovalRequest() {
    const projectCategoryId = params.projectCategoryId;
    const dataId = params.dataId;
    if (projectCategoryId && !dataId) {
      setDataApprovalRequest({id: undefined});
      fetchDepartments();
      reset({
        project_category_id: parseInt(projectCategoryId),
        create_user_id: user?.id,
        approval_request_status_id: Config.APPROVAL_REQUEST_STATUS_YET_ID,
        draft_date: moment().format('YYYY-MM-DD'),
      });
      return;
    };
    if (!projectCategoryId || !dataId) return;
    setLoading(true);

    API.getDataApprovalRequest({
      data_id: parseInt(dataId, 10),
      project_category_id: parseInt(projectCategoryId, 10),
    }).then(response => {
      if (response.data.dataApprovalRequest) {
        let dataApprovalRequest = response.data.dataApprovalRequest as ApproveForm;
        if (props.isType === Config.Data.ApproveCopy) {
          delete dataApprovalRequest.id
          delete dataApprovalRequest.data_id
          dataApprovalRequest?.data_approval_decision_users?.forEach(value => {
            delete value.id;
          })
        }
        setDataApprovalRequest(dataApprovalRequest);
        if (dataApprovalRequest?.approval_request_status_id !== Config.APPROVAL_REQUEST_STATUS_APPLIED_ID) {
          const department_id = response.data.dataApprovalRequest.approver_pattern.department_id;
          fetchDepartments(department_id, dataApprovalRequest.approver_pattern_id, dataApprovalRequest.draft_date);
        } else {
          reset(dataApprovalRequest);
        }
        setIsSetup(true);
      } else {
        setDataApprovalRequest({id: undefined});
        fetchDepartments();
        reset({
          data_id: parseInt(dataId),
          project_category_id: parseInt(projectCategoryId),
          create_user_id: user?.id,
          approval_request_status_id: Config.APPROVAL_REQUEST_STATUS_YET_ID,
          draft_date: moment().format('YYYY-MM-DD'),
        });
      }

      // const { dataApprovalRequest, decisionUsers } = res.data;
      // if (dataApprovalRequest) {
      //   setCreateUser(dataApprovalRequest.create_user);
      //   initValues(dataApprovalRequest, decisionUsers);
      // } else {
      //   setCreateUser(user);
      //   methods.setValue('create_user_id', user?.id);
      //   methods.setValue('draft_date', moment().format('YYYY-MM-DD'));
      // }
      // setDecisionUsers(decisionUsers);
      // setData(dataApprovalRequest);
    }).catch(e => {
      console.log(e);
    }).finally(() => {
      setLoading(false);
    });
  }

  async function fetchDepartments(department_id?: number, approver_pattern_id?: number, draft_date?: string) {
    axios.get("/api/v1/approverManagement/getDepartments").then(( response ) => {
      setDepartments(response.data.departments);
      if (department_id) {
        setSelectedDepartment(department_id);
        fetchApproverPattern(department_id, approver_pattern_id, draft_date);
      }
      else fetchApproverPattern(response.data.departments[0]?.id);
    }).catch(error => {
      // setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }

  function resetApproverPattern(approverPattern: ApproverPattern) {
    setValue('approver_pattern_id', approverPattern?.id);
    setValue('data_approval_decision_users', approverPattern.approver_members.map(value => ({
      ...value,
      id: undefined,
      approver_member_id: value.id,
      approval_decision_status_id: Config.APPROVAL_DECISION_STATUS_YET_ID,
      approval_decision_datetime: undefined,
      comments: "",
      is_decision_user: 1,
      // decision_users_id: undefined,
    })));
  }

  async function fetchApproverPattern(department_id?: number, approver_pattern_id?: number, draft_date?: string) {
    const params = {
      department_id: department_id,
      // approver_pattern_id: approver_pattern_id,
      draft_date: draft_date,
    }
    axios.get("/api/v1/approverManagement/getApproverPatterns", {
      params
    }).then(( response ) => {
      const approverPatterns = response.data.approver_patterns as ApproverPattern[];
      setApproverPatterns(approverPatterns);
      if (!approver_pattern_id) {
        const approver_pattern_id = getValues().approver_pattern_id;
        const approverPattern = approverPatterns.find(value => value.id == approver_pattern_id) ?? approverPatterns[0];
        resetApproverPattern(approverPattern);
      } else {
        setTimeout(() => {
          setValue('approver_pattern_id', approver_pattern_id);
        }, 100)

      }
    }).catch(error => {
      // setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }

  const gernerateLinkableWidget = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  const onChangeDeparts = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedDepartment(parseInt(selectedValue));
    fetchApproverPattern(parseInt(selectedValue));
  }

  const onChangeApproverPatterns = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(e.target.value);
    const approverPattern = approverPatterns.find(value => value.id === selectedValue);
    if (approverPattern) resetApproverPattern(approverPattern);
  }

  const onChangeDraftDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDate = e.target.value;
    fetchApproverPattern(selectedDepartment, undefined, selectedDate);
  }

  const onClickCancel = () => {
    setBlocker(false);
    setIsShowCancelModal(true);
  };

  const onSubmit = (submitData: any) => {
    setBlocker(false);

    setLoading(true);
    if (isCategoryApproval) {
      API.postApprovalManagement(submitData).then((res) => {
        if (res.data.result) {
          navigate(Common.approveDetailUrl(params.projectCategoryId, res.data.result.data_id));
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
      }).catch(error => {
        setErrorMessage(Config.MESSAGE_NO_E37);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      API.postDataApprovalRequest(submitData).then((res) => {
        if (res.data.result) {
          navigate(Common.approveDetailUrl(params.projectCategoryId, params.dataId));
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
      }).catch(error => {
        setErrorMessage(Config.MESSAGE_NO_E37);
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const renderDecisionUser = (dataApprovalDecisionUser: FieldArrayWithId<ApproveForm, "data_approval_decision_users", "key">, index: number) => {
    const isApproved = dataApprovalDecisionUser?.approval_decision_status_id === Config.APPROVAL_DECISION_STATUS_APPROVED_ID;
    const approvalDecisionDatetime =
      dataApprovalDecisionUser?.approval_decision_datetime
        ? moment(dataApprovalDecisionUser?.approval_decision_datetime).format(
            "YYYY/MM/DD HH:mm"
          )
        : "";
    const isMe = dataApprovalDecisionUser.user_id === user?.id;
    const showComment = isApplied && (isMe || isAdmin);
    const error = methods.formState.errors.data_approval_decision_users?.[index]?.approval_decision_datetime?.message;

    let content = <></>;

    if (!isApplied) {
      content = (
        <label>
          { dataApprovalDecisionUser.user_name }　　{ dataApprovalDecisionUser.approver_group_name }
        </label>
      )
    } else {
      content = (
        <div className="uk-flex uk-flex-middle uk-margin-small-right">
          <div className="uk-width-small uk-margin-small-left">
            { dataApprovalDecisionUser.user_name }　　{ dataApprovalDecisionUser.approver_group_name }
          </div>
          <div className="uk-text-nowrap uk-margin-small-left">
            {isApproved ? `承認済み` : "未承認"}
          </div>
          {isApproved ? (isAdmin || isCreator) ?
              <div className="uk-text-nowrap uk-margin-small-left">
                <>
                  <input
                    type="datetime-local"
                    className={` ${
                      error ? "error-form" : ""
                    }`}
                    {...methods.register(`data_approval_decision_users.${index}.approval_decision_datetime`, {
                    })}
                  />
                  {error && <div className="error">{ error }</div>}
                </>
              </div>
              :
              <div className="uk-text-nowrap uk-margin-small-left">
                {approvalDecisionDatetime}
              </div>
            : null}
        </div>
      )
    }

    return (
      <tr key={`approver_member_${dataApprovalDecisionUser.key}`}>
        <td className="label">{ dataApprovalDecisionUser.official_position_name }</td>
        <td className={`content `}>
          {content}
        </td>
        <td className={``}>
          {showComment && renderEditComment(index)}
        </td>
      </tr>
    );
  }

  function renderEditComment(index: number) {
    const error = methods.formState.errors.data_approval_decision_users?.[index]?.comments?.message;
    return (
      <>
        <textarea
          className={`uk-width-full ${
            error ? "error-form" : ""
          }`}
          {...methods.register(`data_approval_decision_users.${index}.comments`, {
            maxLength: {
              value: 50,
              message: Config.MESSAGE_NO_E35(50),
            },
          })}
        />
        {error && <div className="error">{ error }</div>}
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      {/*<button type="button" onClick={()=> {console.log("selectedDepartment: ", selectedDepartment, "getValues(): ", getValues())}}>debug</button>*/}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="approve">
          <div className="sub-header" style={{top: headerTop}}>
            <div className="uk-flex uk-flex-right uk-margin-small-top">
              <button
                className="uk-button--m uk-button-cancel"
                type="button"
                onClick={onClickCancel}
              >
                キャンセル
              </button>
              <button
                className="uk-button--m uk-button-refer uk-margin-left"
                type="submit"
              >
                保存
              </button>
            </div>
          </div>
          <div className="container">
            {Object.keys(methods.formState.errors).length > 0 && <div className="error-box">入力エラーがあります</div>}
            <div className="uk-flex uk-flex-between uk-margin-small-top">
              <div className="uk-flex">
                {!isApplied &&
                    <>
                        <select className="select-department uk-margin-small-right" onChange={onChangeDeparts} value={selectedDepartment}>
                          {departments.map(( item, index ) => (
                            <option key={`department-${item.id}`} value={item.id}>{item.name}</option>
                          ))}
                        </select>

                        <select className="select-department"
                                {...register(`approver_pattern_id`, {
                                  valueAsNumber: true,
                                  onChange: onChangeApproverPatterns
                                })}>
                          {approverPatterns.map(( item, index ) => (
                            <option key={`approver-patterns-${item.id}`} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                    </>
                }
              </div>
              <table className="uk-width-1-2">
                <tr>
                  <td className="uk-width-1-6 header">作成者</td>
                  <td className="uk-width-1-3"><input type="hidden" name="create_user_id"/>{createUser?.last_name} {createUser?.first_name}</td>
                  <td className="uk-width-1-6 header">起案者</td>
                  <td className="uk-width-1-3">
                    {isApplied && <>{dataApprovalRequest?.draft_user?.last_name} {dataApprovalRequest?.draft_user?.first_name}</>}
                    {!isApplied && (
                      <UserSearchBar name="draft_user_id" defaultUser={dataApprovalRequest?.draft_user} />
                    )}
                  </td>
                </tr>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <thead>
                  <tr>
                    <th colSpan={2} className="uk-width-1-2 uk-text-center">
                      決裁者
                    </th>
                    <th className="uk-width-1-2 uk-text-center">コメント</th>
                  </tr>
                </thead>
                <tbody>
                  {memberFields.map(renderDecisionUser)}
                </tbody>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <tbody>
                  {isCategoryApproval ?
                    <>
                      <tr>
                        <td className="label">起案</td>
                        <td className="content">
                          {isApplied && (
                            dataApprovalRequest?.draft_date ?? ''
                          )}
                          {!isApplied && (
                            <input
                              disabled={isApplied}
                              type="date"
                              className={`uk-width-1-1 ${
                                methods.formState.errors.draft_date?.message
                                  ? "error-form"
                                  : ""
                              }`}
                              {...methods.register('draft_date', {onChange: onChangeDraftDate})}
                            />
                          )}
                          {methods.formState.errors.draft_date && <span className="error">{methods.formState.errors.draft_date.message?.toString()}</span>}
                        </td>
                        <td className="label">決裁</td>
                        <td className="content">
                          {isApplied && (
                            dataApprovalRequest?.decision_date ?? ''
                          )}
                          {!isApplied && (
                            <input
                              disabled={isApplied}
                              type="date"
                              className={`uk-width-1-1 ${
                                methods.formState.errors.decision_date?.message
                                  ? "error-form"
                                  : ""
                              }`}
                              {...methods.register('decision_date')}
                            />
                          )}
                          {methods.formState.errors.decision_date && <span className="error">{methods.formState.errors.decision_date.message?.toString()}</span>}
                        </td>
                      </tr>
                    </>
                    :
                      <>
                        <tr>
                          <td className="label">起案</td>
                          <td className="content">
                            {isApplied && (
                              dataApprovalRequest?.draft_date ?? ''
                            )}
                            {!isApplied && (
                              <input
                                disabled={isApplied}
                                type="date"
                                className={`uk-width-1-1 ${
                                  methods.formState.errors.draft_date?.message
                                    ? "error-form"
                                    : ""
                                }`}
                                {...methods.register('draft_date', {onChange: onChangeDraftDate})}
                              />
                            )}
                            {methods.formState.errors.draft_date && <span className="error">{methods.formState.errors.draft_date.message?.toString()}</span>}
                          </td>
                          <td className="label">施行</td>
                          <td className="content">
                            {isApplied && (
                              dataApprovalRequest?.execution_date ?? ''
                            )}
                            {!isApplied && (
                              <input
                                disabled={isApplied}
                                type="date"
                                className={`uk-width-1-1 ${
                                  methods.formState.errors.execution_date?.message
                                    ? "error-form"
                                    : ""
                                }`}
                                {...methods.register('execution_date')}
                              />
                            )}
                            {methods.formState.errors.execution_date && <span className="error">{methods.formState.errors.execution_date.message?.toString()}</span>}
                          </td>
                        </tr>
                        <tr>
                          <td className="label">決裁</td>
                          <td className="content">
                            {isApplied && (
                              dataApprovalRequest?.decision_date ?? ''
                            )}
                            {!isApplied && (
                              <input
                                disabled={isApplied}
                                type="date"
                                className={`uk-width-1-1 ${
                                  methods.formState.errors.decision_date?.message
                                    ? "error-form"
                                    : ""
                                }`}
                                {...methods.register('decision_date')}
                              />
                            )}
                            {methods.formState.errors.decision_date && <span className="error">{methods.formState.errors.decision_date.message?.toString()}</span>}
                          </td>
                          <td className="label">発送</td>
                          <td className="content">
                            {isApplied && (
                              dataApprovalRequest?.send_date ?? ''
                            )}
                            {!isApplied && (
                              <input
                                disabled={isApplied}
                                type="date"
                                className={`uk-width-1-1 ${
                                  methods.formState.errors.send_date?.message
                                    ? "error-form"
                                    : ""
                                }`}
                                {...methods.register('send_date')}
                              />
                            )}
                            {methods.formState.errors.send_date && <span className="error">{methods.formState.errors.send_date.message?.toString()}</span>}
                          </td>
                        </tr>
                      </>
                  }
                </tbody>
              </table>
            </div>
            <div className="approve-tbl">
              <table className="uk-width-1-1">
                <tbody>
                  <tr>
                    <td className="label">決裁内容</td>
                    <td className="uk-width-4-5">
                      {isApplied && (
                        <div className="line-break h-195 uk-overflow-auto">
                          <p>{dataApprovalRequest?.decision_content}</p>
                        </div>
                      )}
                      {!isApplied && (
                        <textarea 
                          className={`uk-width-1-1 h-195 ${
                            methods.formState.errors.decision_content?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('decision_content', {
                            maxLength: {
                              value: 500,
                              message: Config.MESSAGE_NO_E35(500),
                            },
                          })}
                        />
                      )}
                      {methods.formState.errors.decision_content && <span className="error">{methods.formState.errors.decision_content.message?.toString()}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="approve-tbl uk-margin-bottom">
              <table className="uk-width-1-1">
                <tbody>
                  <tr>
                    <td className="label">備考</td>
                    <td className="uk-width-4-5">
                      {isApplied && (
                        <div className="line-break h-111 uk-overflow-auto">
                          {gernerateLinkableWidget(dataApprovalRequest?.remarks ?? '')}
                        </div>
                      )}
                      {!isApplied && (
                        <textarea 
                          disabled={isApplied}
                          className={`uk-width-1-1 h-111 ${
                            methods.formState.errors.remarks?.message
                              ? "error-form"
                              : ""
                          }`}
                          {...methods.register('remarks', {
                            maxLength: {
                              value: 500,
                              message: Config.MESSAGE_NO_E35(500),
                            },
                          })}
                        />
                      )}
                      {methods.formState.errors.remarks && <span className="error">{methods.formState.errors.remarks.message?.toString()}</span>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ConfirmModal
            text="登録をキャンセルしますか？入力したデータは登録されません。"
            confirmButtonText="OK"
            isShow={isShowCancelModal}
            onConfirm={() => {
              setIsShowCancelModal(false);
              navigate(Common.approveDetailUrl(params.projectCategoryId, params.dataId));
            }}
            onCancel={() => {
              setIsShowCancelModal(false);
            }}
          />
          {errorMessage &&
              <ConfirmModal
                  text={errorMessage}
                  confirmButtonText="OK"
                  isShow={errorMessage != null}
                  onConfirm={() => {
                    setErrorMessage(null);
                  }}
              />
          }
          <BlockModal />
        </div>
      </form>
    </FormProvider>
  );
}
