import { useUserStore } from "@/stores/user";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Common from "@/utils/common";
import * as Config from "@/config";

/**
 * ユーザのアクセス権限チェックをし、権限が無ければリダイレクト
 */
export const useAdminAuthCheck = (restriction:string) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const hasPermission = Common.hasContentAccessPermission(restriction, user);
  console.log(`${restriction}：${hasPermission}`)
  useEffect(() => {
    if (!hasPermission) {
      navigate("/admin");
    }
  }, [hasPermission, navigate]);
};
