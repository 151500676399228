import React, { useEffect, useState } from "react";
import * as Config from "@/config";
import { CATEGORY_CODE_FAQ } from "@/config";
import * as Common from "@/utils/common";
import { useLocation, useParams } from "react-router-dom";
import { axios } from "@/lib/axios";
import { useLoadingStore } from "@/stores/loading";
import { useProjectStore } from "@/stores/project";
import { useMessageModalStore } from "@/stores/message";
import { Simulate } from "react-dom/test-utils";
import { MapDrawing } from "@/components/Form/Attributes";
import { OpenMapButton } from "@/components/OpenMapButton";
import { DetailUpdatedMunicipalitiesContainer as RenderUpdatedMunicipalitiesContainer } from "@/components/Attributes/BasicMap/DetailUpdatedMunicipalitiesContainer";
import { DetailCertificationContainer as CertificationContainer } from "@/components/Attributes/BasicMap/DetailCertificationContainer";
import { DetailBasicMapStatus } from "@/components/Attributes/BasicMap/BasicMapStatus";
import { useBasicMap } from "@/utils/useBasicMap";
import {CustomMarkdown} from "@/components/CustomMarkdown";

interface AttributeDetailProps {
  isType: Config.Data;
  tab: any;
  tabIndex: number;
}

interface MeshInfo {
  id: number;
  name: string;
  secondary_mesh_code: string;
}

export default function AttributeDetail(props: AttributeDetailProps) {
  const params = useParams();
  const location = useLocation();

  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore((state) => state.setMessage);
  const [selectedProject, selectedProjectCategory] = useProjectStore(
    (state) => [state.project, state.projectCategory]
  );

  const [areaName, setAreaName] = useState<string>("エリア");
  const [blockName, setBlockName] = useState<string>("ブロック");
  const [areas, setAreas] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [tabStatuses, setTabStatuses] = useState<any[]>([]);
  const [data, setData] = useState<any>(undefined); // タブごと
  const tabStatus = data?.data_tab_statuses?.[0]
    ? tabStatuses.find((tabStatus) => {
        return tabStatus.id === data.data_tab_statuses[0].tab_statuses_id;
      })
    : undefined;
  const dataAttributeValueMap = data
    ? Common.convertToKeyMap(data, "attribute_id")
    : new Map<string, any>();

  const isBasicMap =
    selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isBasicMapStatus = isBasicMap && props.tabIndex === 0;
  const isHiddenRemarks = isBasicMap && props.tabIndex === 1;

  useEffect(() => {
    getInputs();
  }, [location.pathname]);

  function getInputs() {
    setLoading(true);
    axios
      .get("/api/v1/attributes/inputs", {
        params: {
          project_id: selectedProject?.id,
          project_category_id: selectedProjectCategory?.id,
          tab_id: props.tab.id,
          tab_index: props.tabIndex,
          data_id: params.dataId,
        },
      })
      .then((result) => {
        setAreaName(result.data.project.area_display_name);
        setBlockName(result.data.project.block_display_name);
        setAreas(result.data.areas);
        setBlocks(result.data.blocks);
        setAttributes(result.data.attributes);
        setTabStatuses(result.data.tabStatuses);
        setData(result.data.data);
      })
      .catch((error) => {
        setMessage(Config.MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <div className="info-tbl_wrap mt-32 mb-40">
      {Common.isServiceStartInfo(selectedProjectCategory) && (
        <>
          <span className="manage-top-circle">●</span><span className="manage-top">は供用予定管理リストの項目</span>、<span className="collect-top-circle">●</span><span className="collect-top">は資料収集リストの項目</span>です。
        </>
      )}
        {tabStatus && (
          <div className="info-tbl">
            <table className="uk-width-1-2">
              <tbody>
                <tr>
                  <td className="label">ステータス</td>
                  <td className="content">
                    {tabStatus.code + ": " + tabStatus.status}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {isBasicMapStatus && <DetailBasicMapStatus />}

        <div className="info-tbl">
          <table className="uk-width-1-1">
            <tbody>
              {props.tabIndex === 0 && attributes[0] ? (
                <InputDefaultRows
                  selectedProjectCategory={selectedProjectCategory}
                  attributes={attributes}
                  data={data}
                  dataAttributeValueMap={dataAttributeValueMap}
                  areas={areas}
                  blocks={blocks}
                  areaName={areaName}
                  blockName={blockName}
                />
              ) : (
                <InputRows
                  attributes={attributes}
                  dataAttributeValueMap={dataAttributeValueMap}
                />
              )}
            </tbody>
          </table>
        </div>

        {isHiddenRemarks && <CertificationContainer />}

        <div
          className="info-tbl"
          style={{ display: isHiddenRemarks ? "none" : "" }}
        >
          <table className="uk-width-1-1">
            <tbody>
              <tr className="h-87">
                <td className="label">備考（内部用）</td>
                <td colSpan={3} className="remarks">
                  <div>{data?.remarks?.replace()}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const RenderRow = (
  attribute: any,
  dataAttributeValueMap: Map<string, any>,
  areaId?: number,
) => {
  const params = useParams();
  const isMapButton = Common.isMapButtonActive(attribute);
  const isMapDrawing = Common.isMapDrawing(attribute);
  const attrValue = dataAttributeValueMap.get(attribute.id);
  const attrValueText = dataAttributeValueMap.get(attribute.id)?.text || "";

  const isViaMesh =
    attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.viaMesh;
  const isRoad = attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.road;

  const [showMeshList, setShowMeshList] = useState<boolean>(false);
  const [showRoadList, setShowRoadList] = useState<boolean>(false);
  const [meshList, setMeshList] = useState<MeshInfo[]>([]);
  const [roadList, setRoadList] = useState<any[]>([]);
  const GetDataBasicMapCitiesParams = {
    attribute_id: attribute.id,
    data_id: Number(params.dataId),
    data_attribute_value_id: attrValue?.id,
  };
  useEffect(() => {
    var dataAttributeValueId = null;
    if (attrValue instanceof Array) {
      dataAttributeValueId = attrValue[0]?.id;
    } else {
      dataAttributeValueId = attrValue?.id;
    }

    if (isViaMesh && dataAttributeValueId) {
      axios
        .get("/api/v1/dicRoadAttributes/getDicViaMeshes", {
          params: {
            attribute_id: attribute.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
          },
        })
        .then((res) => {
          setMeshList(res.data.via_meshes);
        });
    } else if (isRoad && dataAttributeValueId) {
      axios
        .get("/api/v1/dicRoadAttributes/getDicRoadAttributes", {
          params: {
            attribute_id: attribute.id,
            data_id: params.dataId,
            data_attribute_value_id: dataAttributeValueId,
          },
        })
        .then((res) => {
          setRoadList(res.data.road_attributes ?? []);
        });
    }
  }, [
    params.dataId,
    attrValue?.id,
    attribute.id,
    isViaMesh,
    isRoad,
    attrValue,
  ]);

  return (
    <>
      <tr className="">
        <td className={`label ${isMapButton && "map-button-active"}`}>
          {attribute.name}
          <span className="collect">{Common.isCollect(attribute) && `●`}</span>
          <span className="manage">{Common.isManage(attribute) && `●`}</span>
          {isMapButton && (
            <OpenMapButton attribute={attribute} attrValue={attrValueText} />
          )}
          {isViaMesh && meshList.length > 0 && (
            <button
              className="uk-button--s uk-button-refer uk-margin-left"
              onClick={() => setShowMeshList(!showMeshList)}
            >
              {showMeshList ? "閉じる" : "詳細表示"}
            </button>
          )}
          {isRoad && roadList.length > 0 && (
            <button
              className="uk-button--s uk-button-refer uk-margin-left"
              onClick={() => setShowRoadList(!showRoadList)}
            >
              {showRoadList ? "閉じる" : "詳細表示"}
            </button>
          )}
        </td>
        <td
          className={`content ${
            isMapDrawing && "uk-position-relative height-300"
          }`}
        >
          {isViaMesh ? (
            <>{meshList.map((item) => item.secondary_mesh_code).join("、")}</>
          ) : isRoad ? (
            <>{roadAttributesCount(roadList)}</>
          ) : (
            chooseComponent(attribute, dataAttributeValueMap)
          )}
        </td>
      </tr>
      {isViaMesh && showMeshList ? (
        <RenderMeshContainer meshList={meshList} />
      ) : isRoad && showRoadList ? (
        <RenderRoadContainer roadList={roadList} />
      ) : (
        <></>
      )}
      {useBasicMap().isUpdatedMunicipalities(attribute) && (
        <RenderUpdatedMunicipalitiesContainer
          attribute={attribute}
          areaId={areaId}
          GetDataBasicMapCitiesParams={GetDataBasicMapCitiesParams}
        />
      )}
    </>
  );
};

const RenderMeshContainer = (props: { meshList: MeshInfo[] }) => {
  const { meshList } = props;

  return (
    <tr className="">
      <td
        colSpan={2}
        style={{
          padding: 24,
          borderLeft: "none",
          borderRight: "none",
          backgroundColor: "white",
        }}
      >
        <div className="">
          <table className="uk-table-small uk-table-middle uk-width-1-2 uk-margin-auto">
            <thead>
              <tr>
                <th className="uk-text-center">
                  <div className="border-box" />
                  No
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  2次メッシュ番号
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  名称
                </th>
              </tr>
            </thead>
            <tbody>
              {meshList.map((mesh, idx) => (
                <tr key={`mesh_${idx}`}>
                  <td className="uk-text-center width-60 white">{idx + 1}</td>
                  <td className="uk-text-left width-200 white">
                    {mesh.secondary_mesh_code}
                  </td>
                  <td className="uk-text-left white">{mesh.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};

const RenderRoadContainer = (props: { roadList: any[] }) => {
  const { roadList } = props;
  const [roadStructures, setRoadStructures] = useState<
    {
      id: number;
      value: string;
    }[]
  >([]);
  const [constructDetails, setConstructDetails] = useState<
    {
      id: number;
      value: string;
    }[]
  >([]);

  const roadStructureIdValue = (dicRoadAttribute: any) => {
    const roadStructure = roadStructures.find(
      (item) =>
        Number(item.id) === Number(dicRoadAttribute.road_structures_code)
    );
    return roadStructure
      ? `${String(roadStructure.id).padStart(2, "0")}: ${roadStructure.value}`
      : "";
  };

  useEffect(() => {
    Promise.all([
      axios.get("/api/v1/dicRoadAttributes/getDicRoadStructures"),
      axios.get("/api/v1/dicRoadAttributes/getDicConstructionDetails"),
    ]).then((res) => {
      setRoadStructures(res[0].data.road_structures ?? []);
      setConstructDetails(res[1].data.construction_details ?? []);
    });
  }, []);

  return (
    <tr className="">
      <td
        colSpan={2}
        style={{
          padding: 24,
          borderLeft: "none",
          borderRight: "none",
          backgroundColor: "white",
        }}
      >
        <div className="">
          <table className="uk-table-small uk-table-middle">
            <thead>
              <tr>
                <th className="uk-text-center">
                  <div className="border-box" />
                  道路属性整理番号
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  基本データ対応番号
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  属性管理番号
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  道路構造
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  工事内容
                </th>
                <th className="uk-text-center">
                  <div className="border-box" />
                  属性名
                </th>
              </tr>
            </thead>
            <tbody>
              {roadList.map((dicRoadAttribute, idx) => (
                <tr key={`road_attribute_${idx}`}>
                  <td
                    className="uk-text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    {dicRoadAttribute.road_attribute_number}
                  </td>
                  <td
                    className="uk-text-center"
                    style={{ width: 200, backgroundColor: "white" }}
                  >
                    {dicRoadAttribute.base_data_number}
                  </td>
                  <td
                    className="uk-text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    {dicRoadAttribute.attribute_management_number}
                  </td>
                  <td
                    className="uk-text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    {roadStructureIdValue(dicRoadAttribute)}
                  </td>
                  <td
                    className="uk-text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    {
                      constructDetails.find(
                        (item) =>
                          Number(item.id) ===
                          Number(dicRoadAttribute.construction_details_code)
                      )?.value
                    }
                  </td>
                  <td
                    className="uk-text-center"
                    style={{ backgroundColor: "white", width: 250 }}
                  >
                    {dicRoadAttribute.attribute_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};

interface AttributeDetailBaseProps {
  selectedProjectCategory: any;
  attributes: Array<any>;
  data: any;
  dataAttributeValueMap: Map<string, any>;
  areas: Array<any>;
  blocks: Array<any>;
  areaName: string;
  blockName: string;
}

const InputDefaultRows = ({
  selectedProjectCategory,
  attributes,
  data,
  dataAttributeValueMap,
  areas,
  blocks,
  areaName,
  blockName,
}: AttributeDetailBaseProps) => {
  const selectedArea = areas.find((area: any) => {
    return area.id === data?.area_id;
  });
  const selectedBlock = blocks.find((block: any) => {
    return block.id === data?.block_id;
  });

  const isHiddenArea =
    selectedProjectCategory?.category_code === CATEGORY_CODE_FAQ;
  const isHiddenBlock =
    selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP ||
    isHiddenArea;

  return (
    <>
      <tr className="">
        <td className="label" key={`input_td_1_${attributes[0].id}`}>
          {attributes[0].name}
          <span className="collect">{Common.isCollect(attributes[0]) && `●`}</span>
          <span className="manage">{Common.isManage(attributes[0]) && `●`}</span>
        </td>
        <td className="content" key={`input_td_2_${attributes[0].id}`}>
          {chooseComponent(attributes[0], dataAttributeValueMap)}
        </td>
      </tr>
      <tr className="" style={{ display: isHiddenArea ? "none" : "" }}>
        <td className="label">{areaName}</td>
        <td className="content">
          {selectedArea.code + ": " + selectedArea.name}
        </td>
      </tr>
      <tr className="" style={{ display: isHiddenBlock ? "none" : "" }}>
        <td className="label">{blockName}</td>
        <td className="content">
          {selectedBlock?.code + ": " + selectedBlock?.name}
        </td>
      </tr>
      {attributes
        .slice(1)
        .map((attribute) =>
          RenderRow(attribute, dataAttributeValueMap, data.area_id)
        )}
    </>
  );
};

const InputRows = ({
  attributes,
  dataAttributeValueMap,
}: {
  attributes: Array<any>;
  dataAttributeValueMap: Map<string, any>;
}) => {
  return (
    <>
      {attributes.map((attribute) =>
        RenderRow(attribute, dataAttributeValueMap)
      )}
    </>
  );
};

const roadAttributesCount = (roadAttributes: any[]) => {
  const totalCount = roadAttributes.length;
  const availableCount = roadAttributes.filter(
    (item) =>
      Number(item.adoption_or_not) ===
      Number(Config.COUNTABLE_ADOPTION_OR_NOT_OPTION)
  ).length;
  return `${availableCount}/${totalCount}`;
};

const chooseComponent = (
  attribute: any,
  dataAttributeValueMap: Map<string, any>
) => {
  const dataAttributeValue = dataAttributeValueMap.get(attribute.id);
  const isMarkDown = Common.isMarkDown(attribute);
  if (dataAttributeValue === undefined) return "";
  if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
    if (Array.isArray(dataAttributeValue)) {
      return (
        <>
          {dataAttributeValue.map((value: any) => {
            return (
              <>
                {`${value.attribute_code}: ${value.attribute_value}`}
                <br />
              </>
            );
          })}
        </>
      );
    } else
      return `${dataAttributeValue.attribute_code}: ${dataAttributeValue.attribute_value}`;
  } else if (
    attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_TEXT
  ) {
    return isMarkDown ? (
      <CustomMarkdown content={dataAttributeValue.text} />
    ) : (
      `${dataAttributeValue.text}`
    );
  } else if (
    attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_MAP_DRAWING
  ) {
    return <MapDrawing value={dataAttributeValue.text} />;
  } else {
    return `${dataAttributeValue.attribute_value}`;
  }
};
