import create from 'zustand';
import {SortParams} from "@/components/SortIndicator";

interface CustomerStore {
  queryParams: any | undefined;
  setQueryParams: (any: any | undefined) => void;
  drmPfCommonState: DrmPfCommonState;
  setDrmPfCommonState: (update: Partial<DrmPfCommonState>) => void;
  childSubmit: () => void;
  setChildSubmit: (submit: () => void) => void;
  sortParams: SortParams;
  setSortParams: (sortParams: SortParams) => void;
  doSort: (sortParams: SortParams) => void;
  setDoSort: (func: CustomerStore['doSort']) => void;
}

export interface DrmPfCommonState {
  customerNumber?: string;
  modified?: string;
  modified_by?: string;
}

const initState = {
  queryParams: undefined,
  drmPfCommonState: {} as DrmPfCommonState,
  childSubmit: () => {
  },
  sortParams: {} as SortParams,
  doSort: () => {
  },
}

// 検索条件の保持に使用
export const useCustomerStore = create<CustomerStore>((set) => ({
    ...initState,
    setQueryParams: (queryParams) =>
      set((state) => ({
        queryParams: queryParams,
      })),
    setDrmPfCommonState: (update) => set((state) => ({
      drmPfCommonState: {...state.drmPfCommonState, ...update}
    })),
    setChildSubmit: (childSubmit) => set({childSubmit: childSubmit}),
    setSortParams: (sortParams) =>
      set((state) => ({
        sortParams: sortParams,
      })),
    setDoSort: (doSort) =>
      set((state) => ({
        doSort: doSort,
      }))
  }))

;
