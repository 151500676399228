import { useState } from "react";
import SubHeader from "../../../components/SubHeader";
import PostalCodeImport from "./PostalCodeImport";
import ConfirmModal from "@/components/ConfirmModal";
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";
import * as Config from "@/config";

export default function PostalCodeManagement() {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_POSTAL_CODE_MANAGEMENT);

  const [modalMessage, setModalMessage] = useState<{
    type: {};
    message: string;
  } | null>(null);

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "郵便番号管理" },
  ];

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={"郵便番号管理"} />
      </div>
      <div className="container">
        <PostalCodeImport />
      </div>
      <ConfirmModal
        text={modalMessage?.message ?? ""}
        confirmButtonText="OK"
        isShow={!!modalMessage?.message}
        onConfirm={() => setModalMessage(null)}
      />
    </div>
  );
}
