import {User} from "@/stores/user";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import {NavigateFunction} from "react-router-dom";

export function useAdmin(navigate: NavigateFunction, user?: User) {
  const isSystemAdminer = user?.roll == Config.PERMISSION_ROLL_SYSADMIN;
  const canUser =
    Common.canUserManagement(user) === Config.RESTRICTION_HISTORY_USER_OK.value;
  const canDataManagement =
    Common.canDataExportManagement(user) ===
    Config.RESTRICTION_HISTORY_DATA_EXPORT_OK.value ||
    Common.canDataImportManagement(user) ===
    Config.RESTRICTION_HISTORY_DATA_IMPORT_OK.value;
  const canInformationManagement =
    Common.canInformationManagement(user) ===
    Config.RESTRICTION_HISTORY_MANAGE_INFORMATION_OK.value;
  const canDocumentManagement =
    Common.canDocumentManagement(user) ===
    Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK.value;
  const canBasicMapManagement =
    Common.canBasicMapManagement(user) ===
    Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK.value;
  const canMaterialCollectionListManagement =
    Common.canMaterialCollectionListManagement(user) ===
    Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_OK.value;
  const canDicDataManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_DIC_DATA_MANAGEMENT, user);
  const canPortalManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_PORTAL_MANAGEMENT, user);
  const canPostalCodeManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_POSTAL_CODE_MANAGEMENT, user);
  const canLayerManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_LAYER_MANAGEMENT, user);
  const canTicketDestination = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_TICKET_DESTINATION_MANAGEMENT, user);
  const canPositionManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_POSITION_MANAGEMENT, user);
  const canDecisionManagement = Common.hasContentAccessPermission(Config.RESTRICTION_HISTORY_DECISION_MANAGEMENT, user);
  const ADMIN_CONTENTS = [
    {
      name: 'グループ管理',
      showContent: isSystemAdminer,
      onClickFunc: () => navigate("/admin/groups"),
      style: 'uk-button--l',
    },
    {
      name: 'ユーザ管理',
      showContent: canUser,
      onClickFunc: () => navigate("/admin/users"),
      style: 'uk-button--l',
    },
    {
      name: 'データ管理',
      showContent: canDataManagement,
      onClickFunc: () => navigate("/admin/data"),
      style: 'uk-button--l',
    },
    {
      name: 'お知らせ管理',
      showContent: canInformationManagement,
      onClickFunc: () => navigate("/admin/information"),
      style: 'uk-button--l',
    },
    {
      name: '資料収集管理',
      showContent: canMaterialCollectionListManagement,
      onClickFunc: () => navigate("/admin/material-collection-list"),
      style: 'uk-button--l',
    },
    {
      name: 'DICデータ管理',
      showContent: canDicDataManagement,
      onClickFunc: () => navigate("/admin/dic"),
      style: 'uk-button--l',
    },
    {
      name: '基盤地図情報管理',
      showContent: canBasicMapManagement,
      onClickFunc: () => navigate("/admin/basic-map"),
      style: 'uk-button--l',
    },
    {
      name: '資料管理',
      showContent: canDocumentManagement,
      onClickFunc: () => navigate("/admin/document"),
      style: 'uk-button--l',
    },
    {
      name: 'DRM-PFポータルサイト管理',
      showContent: canPortalManagement,
      onClickFunc: () => navigate("/admin/portal"),
      style: 'uk-button--w',
    },
    {
      name: '郵便番号管理',
      showContent: canPostalCodeManagement,
      onClickFunc: () => navigate("/admin/postal-code"),
      style: 'uk-button--l',
    },
    {
      name: '作図管理',
      showContent: canLayerManagement,
      onClickFunc: () => navigate("/admin/layer"),
      style: 'uk-button--l',
    },
    {
      name: 'チケット管理',
      showContent: canTicketDestination,
      onClickFunc: () => navigate("/admin/ticket-destination"),
      style: 'uk-button--l',
    },
    {
      name: '役職管理',
      showContent: canPositionManagement,
      onClickFunc: () => navigate("/admin/positions"),
      style: 'uk-button--l',
    },
    {
      name: '決済管理',
      showContent: canDecisionManagement,
      onClickFunc: () => navigate("/admin/approverPatterns"),
      style: 'uk-button--l',
    },
  ];

  return {ADMIN_CONTENTS}
}