import * as Config from "@/config";
import {useProgressStore} from "@/stores/progress";
import {useCustomerStore} from "@/stores/customer";

interface BaseSortType {
  item_name: string;
  attribute_code?: string;
  column_name?: string;
  position_id?: number;
}

export type SortIndicatorProps = BaseSortType & {
  isCustomer?: boolean;
}

export type SortParams = BaseSortType & {
  order: number;
}

enum SortOrder {
  ASC = 1,
  DESC = 2
}

export const SortIndicator = (props: SortIndicatorProps) => {
  const [progressSortParams, setProgressSortParams, progressDoSort] = useProgressStore(state => [
    state.sortParams,
    state.setSortParams,
    state.doSort
  ]);
  const [customerSortParams, setCustomerSortParams, customerDoSort] = useCustomerStore(state => [
    state.sortParams,
    state.setSortParams,
    state.doSort
  ]);

  const sortParams = props.isCustomer ? customerSortParams : progressSortParams;
  const setSortParams = props.isCustomer ? setCustomerSortParams : setProgressSortParams;
  const doSort = props.isCustomer ? customerDoSort : progressDoSort;

  const isActive = (order: number) => {
    const bool = props.item_name === sortParams.item_name
      && props.attribute_code == sortParams.attribute_code
      && props.column_name == sortParams.column_name
      && props.position_id == sortParams.position_id
      && sortParams.order == order;
    return bool ? 'active' : '';
  };

  const onClickSort = (order: SortOrder) => {
    setSortParams({...props, order});
    doSort({...props, order});
  }

  return (
    <div className="sort-indicator">
      <button className={`arrow-btn arrow-up ${isActive(SortOrder.ASC)}`}
              onClick={() => onClickSort(SortOrder.ASC)}
              aria-label="昇順ソート"></button>
      <button className={`arrow-btn arrow-down ${isActive(SortOrder.DESC)}`}
              onClick={() => onClickSort(SortOrder.DESC)}
              aria-label="降順ソート"></button>
    </div>
  )
}