import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLoadingStore } from "@/stores/loading";
import { useUserStore } from "@/stores/user";
import * as API from "@/api/approval";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import {ApproveForm, DataApprovalDecisionUser} from "./types";
import moment from "moment";

export default function ApproveDetail() {
  const headerTop =
    (document.querySelector("header")?.offsetHeight ?? 0) +
    (document.getElementById("dataSubHeader")?.offsetHeight ?? 0);

  const navigate = useNavigate();
  const params = useParams();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const user = useUserStore((state) => state.user);

  const [data, setData] = useState<any | null>();
  const [dataApprovalRequest, setDataApprovalRequest] = useState<any | null>(null);

  const isApplied = useMemo(() => {
    return (
      dataApprovalRequest?.approval_request_status_id ===
      Config.APPROVAL_REQUEST_STATUS_APPLIED_ID
    );
  }, [dataApprovalRequest]);

  // 作成者 or 起案者
  const isCreator = useMemo(() => {
    if (dataApprovalRequest && user) {
      return dataApprovalRequest.create_user_id === user.id || dataApprovalRequest.draft_user_id == user.id;
    }
    return !dataApprovalRequest;
  }, [dataApprovalRequest, user]);

  // システム管理者 or 決済管理権限
  const isAdmin = useMemo(() => {
    if (dataApprovalRequest && user) {
      return (
        user.roll === Config.PERMISSION_ROLL_SYSADMIN ||
        (user.user_restrictions.find(
          (restriction) =>
            restriction.category_restriction_code ===
              Config.RESTRICTION_HISTORY_APPROVE &&
            restriction.category_restriction_value ===
              Config.RESTRICTION_HISTORY_APPROVE_ADMIN.value &&
            String(restriction.project_category_id) === params.projectCategoryId
        )
          ? true
          : false)
      );
    }
    return false;
  }, [dataApprovalRequest, user]);

  const isViewer = useMemo(() => {
    if (dataApprovalRequest && user) {
      return user.user_restrictions.find(
        (restriction) =>
          restriction.category_restriction_code ===
            Config.RESTRICTION_HISTORY_APPROVE &&
          restriction.category_restriction_value ===
            Config.RESTRICTION_HISTORY_APPROVE_VIEW.value &&
          String(restriction.project_category_id) === params.projectCategoryId
      )
        ? true
        : false;
    }
    return false;
  }, [dataApprovalRequest, user]);

  const enableEditButton = useMemo(() => {
    const decisionUsers = dataApprovalRequest?.data_approval_decision_users ?? [];
    if (decisionUsers.length == 0) {
      return false;
    }
    if (dataApprovalRequest && user && decisionUsers.length > 0) {
      // 決裁者
      const isApprover = decisionUsers.find(
        (decisionUser: DataApprovalDecisionUser) => decisionUser.user_id === user.id
      );

      if (isApplied) {
        return isAdmin || (isApprover && isViewer);
      } else {
        return isAdmin || isCreator;
      }
    }
    return !dataApprovalRequest && user;
  }, [dataApprovalRequest, isAdmin, isViewer, isApplied]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    const projectCategoryId = params.projectCategoryId;
    const dataId = params.dataId;
    if (!projectCategoryId || !dataId) return;
    setLoading(true);
    API.getDataApprovalRequest({
      data_id: parseInt(dataId, 10),
      project_category_id: parseInt(projectCategoryId, 10),
    })
      .then((response) => {
        const dataApprovalRequest = response.data.dataApprovalRequest as ApproveForm;
        if (!dataApprovalRequest) {
          navigate(Common.approveEditUrl(params.projectCategoryId, params.dataId));
        } else {
          setDataApprovalRequest(dataApprovalRequest);
          setData(response.data.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onClickEdit = () => {
    navigate(Common.approveEditUrl(params.projectCategoryId, params.dataId));
  };

  const onClickApply = () => {
    if (!dataApprovalRequest) return;
    setLoading(true);
    API.postDataApprovalRequest({
      id: dataApprovalRequest.id,
      approval_request_status_id: Config.APPROVAL_REQUEST_STATUS_APPLIED_ID,
    })
      .then((res) => {
        fetchData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSendingBack = () => {
    setLoading(true);
    API.postDataApprovalRequest({
      id: dataApprovalRequest.id,
      approval_request_status_id: Config.APPROVAL_REQUEST_STATUS_YET_ID,
    })
      .then((res) => {
        fetchData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const gernerateLinkableWidget = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        // If the part is a URL, convert it to a clickable link
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If it's regular text, just display it as-is
        return <span key={index}>{part}</span>;
      }
    });
  };

  const onClickApprove = (approvalDecisionUser: DataApprovalDecisionUser, index: number) => {
    setLoading(true);
    API.postDataApprovalDecisionUser({
      id: approvalDecisionUser.id,
      approval_decision_status_id: Config.APPROVAL_DECISION_STATUS_APPROVED_ID,
    })
      .then((res) => {
        fetchData();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickApproveCancel = (approvalDecisionUser: DataApprovalDecisionUser, index: number) => {
    setLoading(true);
    API.postDataApprovalDecisionUser({
      id: approvalDecisionUser.id,
      approval_decision_status_id: Config.APPROVAL_DECISION_STATUS_YET_ID,
    })
      .then((res) => {
        fetchData();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderDecisionUser = (dataApprovalDecisionUser: DataApprovalDecisionUser, index: number) => {
    // const approvalDecisionUser = data?.data_approval_decision_users?.find(
    //   (user: any) => user.decision_users_id === dataApprovalDecisionUser.id
    // );
    const isDecisionUser = dataApprovalDecisionUser?.is_decision_user == 1;
    const isApproved =
      dataApprovalDecisionUser?.approval_decision_status_id ==
      Config.APPROVAL_DECISION_STATUS_APPROVED_ID;
    const approvalDecisionDatetime =
      dataApprovalDecisionUser?.approval_decision_datetime
        ? moment(dataApprovalDecisionUser?.approval_decision_datetime).format(
            "YYYY/MM/DD HH:mm"
          )
        : "";
    const isDisabled = !dataApprovalDecisionUser || !isDecisionUser;
    const isMe = dataApprovalDecisionUser.user_id === user?.id;

    let content = <></>;
    if (isApplied && (isMe || isAdmin) && isDecisionUser) {
      content = isApproved ? (
        <div className="uk-flex uk-flex-middle uk-margin-small-right">
          <div className="uk-width-small uk-margin-small-left">{dataApprovalDecisionUser.user_name}</div>
          <button
            className="uk-button--s uk-button-cancel uk-margin-small-left"
            onClick={() => onClickApproveCancel(dataApprovalDecisionUser, index)}
          >
            取り消し
          </button>
          <div className="uk-text-nowrap uk-margin-small-left">{approvalDecisionDatetime}</div>
        </div>
      ) : (
        <div className="uk-flex uk-flex-middle uk-margin-small-right">
          <div className="uk-width-small uk-margin-small-left">{dataApprovalDecisionUser.user_name}</div>
          <button
            className="uk-button--s uk-button-refer uk-margin-small-left"
            onClick={() => onClickApprove(dataApprovalDecisionUser, index)}
          >
            承認
          </button>
          <button
            className="uk-button--s uk-button-cancel uk-margin-small-left"
            onClick={() => onClickSendingBack()}
          >
            差戻
          </button>
        </div>
      );
    } else {
      content = (
        <div className="uk-flex uk-flex-middle uk-margin-small-right">
          <div className="uk-width-small uk-margin-small-left">
            {dataApprovalDecisionUser.user_name}
          </div>
          {isDecisionUser && (
            <>
              <div className="uk-margin-small-left">
                {isApproved ? `承認済み` : "未承認"}
              </div>
              {isApproved && (
                <div className="uk-text-nowrap uk-margin-small-left">
                  {approvalDecisionDatetime}
                </div>
              )}
            </>
          )}
        </div>
      );
    }

    return (
      <tr key={`approve_${index}_${dataApprovalDecisionUser.id}`}>
        <td className="label">{dataApprovalDecisionUser.official_position_name}</td>
        <td className={`${isDisabled ? "disabled" : ""}`}>{content}</td>
        <td className={`${isDisabled ? "disabled" : ""}`}>
          {dataApprovalDecisionUser.comments}
        </td>
      </tr>
    );
  };

  console.log("isCreator: ", isCreator, "isViewer: ", isViewer, "isAdmin: ", isAdmin, "isApplied: ", isApplied)
  return (
    <div>
      <div className="sub-header" style={{ top: headerTop }}>
        <div className="uk-flex uk-flex-right uk-margin-small-top">
          {dataApprovalRequest && ((isCreator && isViewer) || isAdmin) && !isApplied &&
              <button
                className="uk-button--m uk-button-yellow"
                onClick={onClickApply}
              >
                申請
              </button>
          }
          {enableEditButton && (
            <button
              className="uk-button--m uk-button-refer uk-margin-small-left"
              onClick={onClickEdit}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <div className="container">
        <div className="uk-flex uk-flex-right uk-margin-small-top">
          <table className="uk-width-1-2">
            <tbody>
              <tr>
                <td className="uk-width-1-6 header">作成者</td>
                <td className="uk-width-1-3">
                  {dataApprovalRequest?.create_user?.last_name} {dataApprovalRequest?.create_user?.first_name}
                </td>
                <td className="uk-width-1-6 header">起案者</td>
                <td className="uk-width-1-3">
                  {dataApprovalRequest?.draft_user?.last_name} {dataApprovalRequest?.draft_user?.first_name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="approve-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <thead>
              <tr>
                <th colSpan={2} className="uk-width-3-5 uk-text-center">
                  決裁者
                </th>
                <th className="uk-width-1-2 uk-text-center">コメント</th>
              </tr>
            </thead>
            <tbody>{dataApprovalRequest?.data_approval_decision_users?.map(renderDecisionUser)}</tbody>
          </table>
        </div>
        <div className="approve-tbl">
          <table className="uk-width-1-1">
            <tbody>
              <tr>
                <td className="label">起案</td>
                <td className="content">{dataApprovalRequest?.draft_date ?? ''}</td>
                <td className="label">施行</td>
                <td className="content">{dataApprovalRequest?.execution_date ?? ''}</td>
              </tr>
              <tr>
                <td className="label">決裁</td>
                <td className="content">{dataApprovalRequest?.decision_date ?? ''}</td>
                <td className="label">発送</td>
                <td className="content">{dataApprovalRequest?.send_date ?? ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="approve-tbl">
          <table className="uk-width-1-1">
            <tbody>
              <tr>
                <td className="label">決裁内容</td>
                <td className="uk-width-4-5">
                  <div className="line-break h-195 uk-overflow-auto">
                    <p>{dataApprovalRequest?.decision_content}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="approve-tbl uk-margin-bottom">
          <table className="uk-width-1-1">
            <tbody>
              <tr>
                <td className="label">備考</td>
                <td className="uk-width-4-5">
                  <div className="h-111 line-break uk-overflow-auto">
                    {gernerateLinkableWidget(dataApprovalRequest?.remarks ?? '')}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
