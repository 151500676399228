import {SharedTableHeadAttributes, SharedTableHeadTabNames} from "@/components/ProgressList/results/SharedTableHead";
import React from "react";
import {useProgressStore} from "@/stores/progress";
import * as Config from "@/config";
import {SharedTableBodyAttributes, SharedTableBodyTabs} from "@/components/ProgressList/results/SharedTableBody";
import {useProgressListSearchResults} from "@/components/ProgressList/useProgressListSearchResults";
import {Attribute} from "@/components/ProgressList/types";
import {SortIndicator} from "@/components/SortIndicator";

export const ServiceStartInfoTableHead = () => {
  const [serviceStartInfoType, listAttributes] = useProgressStore(state => [state.serviceStartInfoType, state.listAttributes]);
  const isProgress = serviceStartInfoType === Config.ServiceStartInformationList.progress;
  const isCollect = serviceStartInfoType === Config.ServiceStartInformationList.collect;
  const {splitServiceStartInfoListAttributes} = useProgressListSearchResults();
  const {
    collectList,
    collectListBasicInfo,
    collectListServiceInfo,
    collectListDrawing,
    collectListReference
  } = splitServiceStartInfoListAttributes(listAttributes);

  return (
    <>
      {isProgress && <ServiceInfoSourceHeader/>}
      <SharedTableHeadAttributes CollectList={isCollect ? collectList : undefined}/>
      {isProgress && <SharedTableHeadTabNames/>}
      {isCollect &&
          <>
              <ServiceStartInfoHeaderName headerName={Config.ServiceStartInfoCollectHeaderName.basicInfo}
                                          colSpan={collectListBasicInfo.length}/>
              <ServiceStartInfoHeaderName headerName={Config.ServiceStartInfoCollectHeaderName.serviceInfo}
                                          colSpan={collectListServiceInfo.length}/>
              <ServiceStartInfoHeaderName headerName={Config.ServiceStartInfoCollectHeaderName.drawing}
                                          colSpan={collectListDrawing.length}/>
              <ServiceStartInfoHeaderName headerName={Config.ServiceStartInfoCollectHeaderName.reference}
                                          colSpan={collectListReference.length}/>
          </>
      }
    </>
  )
}

interface ServiceStartInfoTableBodyProps {
  data: any;
  checked: boolean;
}

export const ServiceStartInfoTableBody = (props: ServiceStartInfoTableBodyProps) => {
  const [serviceStartInfoType, listAttributes] = useProgressStore(state => [state.serviceStartInfoType, state.listAttributes]);
  const isProgress = serviceStartInfoType === Config.ServiceStartInformationList.progress;
  const isCollect = serviceStartInfoType === Config.ServiceStartInformationList.collect;
  const {splitServiceStartInfoListAttributes} = useProgressListSearchResults();
  const {
    collectList,
    collectListBasicInfo,
    collectListServiceInfo,
    collectListDrawing,
    collectListReference
  } = splitServiceStartInfoListAttributes(listAttributes);
  return (
    <>
      {isProgress && <ServiceInfoSourceBody {...props}/>}
      <SharedTableBodyAttributes {...props} CollectList={isCollect ? collectList : undefined}/>
      {isProgress && <SharedTableBodyTabs data={props.data}/>}
      {isCollect &&
          <>
              <SharedTableBodyAttributes {...props} CollectList={collectListBasicInfo}/>
              <SharedTableBodyAttributes {...props} CollectList={collectListServiceInfo}/>
              <SharedTableBodyAttributes {...props} CollectList={collectListDrawing}/>
              <SharedTableBodyAttributes {...props} CollectList={collectListReference}/>
          </>
      }
    </>
  )
}


export const ServiceStartInfoTableHeadAttributes = () => {
  const listAttributes = useProgressStore(state => state.listAttributes);
  const {splitServiceStartInfoListAttributes} = useProgressListSearchResults();
  const {
    collectListBasicInfo,
    collectListServiceInfo,
    collectListDrawing,
    collectListReference
  } = splitServiceStartInfoListAttributes(listAttributes);

  return (
    <>
      <ServiceStartInfoTableHeadAttribute attributes={collectListBasicInfo}
                                          headerName={Config.ServiceStartInfoCollectHeaderName.basicInfo}/>
      <ServiceStartInfoTableHeadAttribute attributes={collectListServiceInfo}
                                          headerName={Config.ServiceStartInfoCollectHeaderName.serviceInfo}/>
      <ServiceStartInfoTableHeadAttribute attributes={collectListDrawing}
                                          headerName={Config.ServiceStartInfoCollectHeaderName.drawing}/>
      <ServiceStartInfoTableHeadAttribute attributes={collectListReference}
                                          headerName={Config.ServiceStartInfoCollectHeaderName.reference}/>
    </>
  )
}

interface ServiceStartInfoTableHeadAttributeProps {
  attributes: Attribute[];
  headerName: string;
}

export const ServiceStartInfoTableHeadAttribute = (props: ServiceStartInfoTableHeadAttributeProps) => {
  return (
    <>
      {props.attributes.map((attr: Attribute) => {
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        return (
          <th key={`service-start-info-attr-head-${attr.id}`} className="uk-padding-remove-horizontal"
              style={{minWidth: width, maxWidth: width}}>
            <div className="border-box"/>
            <div className="status-box">
              <div className="line-break">
                <span>{attr.name}</span>
              </div>
            </div>
            <SortIndicator item_name={Config.ProgressListSortItem.ATTRIBUTES} column_name={attr.column_name}/>
          </th>
        )
      })}
    </>
  )
}

export const ServiceStartInfoHeaderName = (props: { colSpan: number, headerName: string }) => {
  return (
    <th colSpan={props.colSpan} className="uk-padding-remove-horizontal">
      <div className="border-box"/>
      <div className="uk-text-center">{props.headerName}</div>
    </th>
  )
}


export const ServiceStartInfoChangeListButtons = () => {
  const [setServiceStartInfoType, serviceStartInfoType] = useProgressStore(
    state => [state.setServiceStartInfoType, state.serviceStartInfoType]
  )
  const selectedListClass = (type: Config.ServiceStartInformationList) => {
    return serviceStartInfoType == type ? 'uk-button-refer' : 'uk-button-cancel';
  }

  const changeServiceStartInfoList = (type: Config.ServiceStartInformationList) => {
    setServiceStartInfoType(type);
  }

  return (
    <>
      <button
        className={`uk-button--l uk-margin-left ${selectedListClass(Config.ServiceStartInformationList.progress)}`}
        onClick={() => changeServiceStartInfoList(Config.ServiceStartInformationList.progress)}
      >進捗一覧
      </button>
      <button
        className={`uk-button--w uk-margin-left ${selectedListClass(Config.ServiceStartInformationList.manage)}`}
        onClick={() => changeServiceStartInfoList(Config.ServiceStartInformationList.manage)}
      >提供用予定管理リスト
      </button>
      <button
        className={`uk-button--l uk-margin-left ${selectedListClass(Config.ServiceStartInformationList.collect)}`}
        onClick={() => changeServiceStartInfoList(Config.ServiceStartInformationList.collect)}
      >資料収集リスト
      </button>
    </>
  )
}


const ServiceInfoSourceHeader = () => {
  return (
    <>
      {Object.entries(Config.ServiceInfoSourceLabels).map(([key, label]) => {
        return <th key={`service-info-source-header-${key}`} rowSpan={2} style={{minWidth: 100, maxWidth: 100}}
                   className="sort-indicator-wrap">
          <div className="border-box"/>
          {label}
          <SortIndicator item_name={Config.ProgressListSortItem.ATTRIBUTES}
                         column_name={Config.ATTRIBUTE_COLUMN_NAME.serviceInfoSource}
                         attribute_code={key}/>
        </th>
      })}
    </>
  )
}

interface ServiceInfoSourceBodyProps {
  data: any;
  checked: boolean;
}

const ServiceInfoSourceBody = ({data, checked}: ServiceInfoSourceBodyProps) => {
  const serviceInfoSourceAttr = useProgressStore(state => state.serviceInfoSourceAttr);
  return (
    <>
      {Object.entries(Config.ServiceInfoSourceLabels).map(([key, label]) => {

        const content: any = data.data_attribute_values.find((value: any) => value.attribute_id == serviceInfoSourceAttr?.id && value.attribute_code == key);
        return (
          <td
            key={`service-info-source-body-${key}`}
            className={`uk-text-left ${checked ? "selected" : ""}`}
            style={{minWidth: 100, maxWidth: 100}}
            data-tooltip-id="progress-tooltip" data-tooltip-html={content && "⚪︎"}
          >
            <div className="border-box"/>
            {content && "⚪︎"}
          </td>)
      })}
    </>
  )
}