import React, { useEffect, useState, ChangeEvent } from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import SubHeader from '@/components/SubHeader';
import ConfirmModal from '@/components/ConfirmModal';
import { useLoadingStore } from "@/stores/loading";
import {axios} from '@/lib/axios';
import * as Config from '@/config'
import {useMessageModalStore} from '@/stores/message'
import * as Common from '@/utils/common'
import {useDrmPfInformationListStore} from '@/stores/drmPfInformation';
import {User, useUserStore} from '@/stores/user'
import qs from 'qs';
import CustomSelect from '@/components/CustomSelect';
import ReactPaginate from 'react-paginate';
import { SubmitHandler, useForm } from "react-hook-form";
import {Tooltip} from 'react-tooltip'
import {useAdminAuthCheck} from "@/containers/admin/useAdminAuthCheck";

export default function DrmPfInformationList() {
  useAdminAuthCheck(Config.RESTRICTION_HISTORY_PORTAL_MANAGEMENT);

  const navigate = useNavigate();
  const {pathname, search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = qs.parse(search.substring(1), {arrayLimit: 0});
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
  const [drmPfInformationQueryParams, setDrmPfInformationQueryParams] = useDrmPfInformationListStore(state => [
    state.queryParams,
    state.setQueryParams,
  ]);

  const setLoading = useLoadingStore(state => state.setLoading);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const setMessage = useMessageModalStore(state => state.setMessage);

  // 検索項目
  const [drmPfInformationStatuses, setDrmPfInformationStatuses] = useState<any[]>([]);
  const [drmPfInformationTypes, setDrmPfInformationTypes] = useState<any[]>([]);
  const [resetForm, setResetForm] = useState<boolean>(false);
  // 検索結果
  const [searchResults, setSearchResults] = useState<any | undefined>(undefined);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // 検索結果の入力項目
  const [checkIds, setCheckIds] = useState(new Set<number>());
  const [selectedDrmPfInformationStatusId, setSelectedDrmPfInformationStatusId] = useState<number | undefined>(undefined);
  const [selectedPageSize, setSelectedPageSize] = useState<string | ''>('100')

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<any>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: {
        control_number: '',
        drm_pf_information_types: '',
        title: '',
        body: '',
        release_date_start: '',
        release_date_end: '',
      }
    }
  );

  const pageSizeOptions = [
    {
      value: '25',
      label: '25件',
    },
    {
      value: '50',
      label: '50件',
    },
    {
      value: '100',
      label: '100件',
    },
    {
      value: '200',
      label: '200件',
    },
  ]

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: 'お知らせ一覧' },
  ];

  function getSearchInputs() {
    setResetForm(false);
    setSearchQueryParam(undefined);
    setSearchResults(undefined);
    setLoading(true);
    axios.get('/api/v1/drmPfInformation/searchInputs').then(result => {
      setDrmPfInformationStatuses(result.data.drmPfInformationStatuses);
      setDrmPfInformationTypes(result.data.drmPfInformationTypes);

      if (Object.keys(query).length > 0) {
        // console.log("query")
        // console.log(query)
        // if (query.attributes) query.attributes = Common.decodeAttributeParams(result.data.attributes, query);
        setSearchQueryParam(query);
      } else if (drmPfInformationQueryParams) {
        // console.log("drmPfInformationQueryParams")
        // console.log(drmPfInformationQueryParams)
        setSearchQueryParam(drmPfInformationQueryParams);
      } else {
        setResetForm(true);
      }
    }).catch(error => {
      console.log(error)
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  // 検索処理
  function doSearch(params: any, isSetForm = false) {
    setLoading(true);
    axios.get('/api/v1/drmPfInformation/search', {
      params: params
    }).then(result => {
      // console.log(result.data)
      // console.log(result.data.pgiongoarams)
      // setPortal(result.data.customers);
      // setSearchQueryParam(result.data.params);
      // setPaging(result.data.paging);
      // setSearchParams(qs.stringify(params));
      // setCustomerQueryParams(params);

      setSearchParams(qs.stringify(params));
      setDrmPfInformationQueryParams(params);
      if (isSetForm) {
        reset({
          control_number: params.control_number,
          drm_pf_information_types: params.drm_pf_information_types,
          title: params.title,
          body: params.body,
          release_date_start: params.release_date_start,
          release_date_end: params.release_date_end,
        });
      }
      setSelectedDrmPfInformationStatusId(params.drm_pf_information_status_id);
      setSelectedPageSize(params.page_size);
      setCheckIds(new Set());
      setSearchResults(result.data);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  // 一括削除
  function deleteIds() {
    setModalConfirm({callback: () => {
        setLoading(true);
        axios.post('/api/v1/drmPfInformation/deleteByIds', {
          ids: Array.from(checkIds),
        }).then(result => {
          doSearch(searchQueryParam ?? defaultSearchParam());
        }).catch(error => {
          setMessage(Config.MESSAGE_NO_E39);
        }).finally(() => {
          setLoading(false);
        });
      }});
  }

  function clearForm() {
    reset();
    navigate(pathname);
  }

  function defaultSearchParam() {
    const data = getValues();
    return {
      control_number: data.control_number,
      drm_pf_information_types: data.drm_pf_information_types,
      title: data.title,
      body: data.body,
      release_date_start: data.release_date_start,
      release_date_end: data.release_date_end,
      drm_pf_information_status_id: null,
      page: 1,
      page_size: selectedPageSize,
    }
  }

  useEffect(() => {
    if (drmPfInformationStatuses.length === 0 &&  drmPfInformationTypes.length === 0) {
      getSearchInputs();
    }
  }, []);

  useEffect(() => {
    if (drmPfInformationTypes && searchQueryParam) {
      doSearch(searchQueryParam, true);
    }
  }, [drmPfInformationTypes, searchQueryParam]);

  useEffect(() => {
    if (drmPfInformationTypes && resetForm) {
      clearForm();
      doSearch(defaultSearchParam());
    }
  }, [drmPfInformationTypes, resetForm]);

  const onClickCheck = (id: number) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  const onClickCheckAll = () => {
    if (checkIds.size === searchResults?.drmPfInformation?.length) {
      setCheckIds(new Set<number>());
    } else {
      setCheckIds(new Set<number>(searchResults?.drmPfInformation?.map((data: any) => data.id)));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  const onClickCopy = () => {
    // const copy = searchResults?.datas.find((data: any) => data.id === Array.from(checkIds)[0]);
    navigate(`/admin/portal/${Array.from(checkIds)[0]}/duplicate`);
  }

  const onClickNew = () => {
    navigate('/admin/portal/new');
  }

  const onSelectedDrmPfInformationStatusId = (id: number | null) => {
    doSearch({
      ...drmPfInformationQueryParams,
      drm_pf_information_status_id: id,
      page: 1,
    });
  }

  const onSelectedPageSize = (pageSize: string) => {
    setSelectedPageSize(pageSize);
    doSearch({
      ...drmPfInformationQueryParams,
      page_size: pageSize,
      page: 1
    });
  }

  const onPageChange = (page: number) => {
    doSearch({
      ...drmPfInformationQueryParams,
      page: page
    });
  }

  const onClear = () => {
    clearForm();
  }

  const onSearch = () => {
    const data = getValues();
    console.log(data)
    doSearch({
      control_number: data.control_number,
      drm_pf_information_types: data.drm_pf_information_types,
      title: data.title,
      body: data.body,
      release_date_start: data.release_date_start,
      release_date_end: data.release_date_end,
      drm_pf_information_status_id: null,
      page: 1,
      page_size: selectedPageSize,
    });
  }

  const totalStatusCount = searchResults?.drmPfInformationStatuses?.reduce((accumulator: any, currentValue: any) => {
    const num = Number(currentValue.count);
    accumulator.count += num;
    return accumulator
  }, {count: 0});

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} />
      </div>

      <div className="container drm-pf-information-container uk-margin-small-top">
        <form onSubmit={handleSubmit(onSearch)}>
          <table className="condition-tbl">
            <tbody>
            <tr className="header">
              <th>管理番号</th>
              <th>情報区分</th>
              <th>タイトル</th>
              <th>内容</th>
              <th>公開日</th>
            </tr>
            <tr>
              <td>
                <input
                  className=""
                  type="text"
                  {...register(`control_number`)}
                />
              </td>
              <td>
                <div className="uk-flex">
                  {drmPfInformationTypes.map((type: any) => (
                    <label className="uk-margin-small-right" key={`drm_pf_information_types.${type.code}`}>
                      <input
                        type="checkbox"
                        value={type.id}
                        {...register(`drm_pf_information_types`)}
                      />
                      {type.code + ": " + type.name}
                    </label>
                  ))}
                </div>
              </td>
              <td>
                <input
                  className=""
                  type="text"
                  {...register(`title`)}
                />
              </td>
              <td>
                <input
                  className=""
                  type="text"
                  {...register(`body`)}
                />
              </td>
              <td>
                <div className="period-box">
                  <input
                    className=""
                    type="date"
                    {...register(`release_date_start`)}
                  />
                  <span>〜</span>
                  <input
                    className=""
                    type="date"
                    {...register(`release_date_end`)}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan={8}>
                <div className="uk-flex uk-flex-center">
                  <button type="submit" className="uk-button--m uk-button-refer">検索</button>
                  <button type="button" onClick={onClear}
                          className="uk-button--m uk-button-cancel uk-margin-left">クリア
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <select className="table-length" defaultValue={selectedPageSize} onChange={(e) => onSelectedPageSize(e.target.value)}>
              <option value="" disabled>表示件数</option>
              {pageSizeOptions.map((o: any) => {
                return <option value={o.value}>{o.label}</option>
              })}
            </select>
          </div>
          <div>
            <button className="uk-button--m uk-margin-left uk-button-cancel" onClick={onClickDeleteIds} disabled={checkIds.size === 0}>一括削除</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickCopy} disabled={checkIds.size !== 1}>複製</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成</button>
          </div>
        </div>

        <div className="drm-pf-information-tbl-wrap mt-5">
          <Tooltip id="drm-pf-information-tooltip" style={{zIndex: 3}}/>
          {searchResults?.drmPfInformation &&
              <table className="drm-pf-information-tbl">
                  <thead>
                  <tr className="header">
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          <div className="uk-flex uk-flex-center">
                              <input
                                  type="checkbox"
                                  onChange={onClickCheckAll}
                                  checked={checkIds.size === searchResults?.drmPfInformation?.length}
                              />
                          </div>
                      </th>
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          管理番号
                      </th>
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          情報区分
                      </th>
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          タイトル
                      </th>
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          内容
                      </th>
                      <th rowSpan={2}>
                          <div className="border-box"/>
                          公開日
                      </th>
                      <th>
                          <div className="border-box"/>
                          公開設定
                      </th>
                  </tr>
                  <tr className="header">
                      <th>
                          <div className="border-box"/>
                          <div className="status-box">
                            {searchResults?.drmPfInformationStatuses?.map((item: any) => {
                              return (
                                <div className="">{
                                  selectedDrmPfInformationStatusId !== item.id ?
                                    <a onClick={() => onSelectedDrmPfInformationStatusId(item.id)}>
                                      {item.name}：{item.count}</a>
                                    :
                                    <span>{item.name}：{item.count}</span>
                                }</div>)
                            })}
                              <div className="">{
                                typeof selectedDrmPfInformationStatusId === "number" ?
                                  <a onClick={() => onSelectedDrmPfInformationStatusId(null)}>全件：{totalStatusCount.count}</a>
                                  :
                                  <span>全件：{totalStatusCount.count}</span>
                              }</div>
                          </div>
                      </th>
                  </tr>
                  </thead>
                  <tbody>
                  {searchResults?.drmPfInformation?.map(( item: any ) => {
                    const checked = checkIds.has(item.id);
                    return (
                      <tr className={checked ? "selected" : ""}>
                        <td className={checked ? "selected" : ""}>
                          <div className="border-box"/>
                          <div className="uk-flex uk-flex-center">
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={() => onClickCheck(item.id)}
                            />
                          </div>
                        </td>
                        <td className={"uk-text-left" + (checked ? " selected" : "")}>
                          <div className="border-box"/>
                          <Link to={`/admin/portal/${item.id}`}>{String(item.id).padStart(5, '0')}</Link>
                        </td>
                        <td className="uk-text-left">
                          <div className="border-box"/>
                          {item.drm_pf_information_type.name}
                        </td>
                        <td className="uk-text-left"
                            data-tooltip-id="drm-pf-information-tooltip"
                            data-tooltip-html={item.title}
                        >
                          <div className="border-box"/>
                          {item.title}
                        </td>
                        <td className="uk-text-left"
                            data-tooltip-id="drm-pf-information-tooltip"
                            data-tooltip-html={item.body}
                        >
                          <div className="border-box"/>
                          {item.body}
                        </td>
                        <td className="uk-text-left">
                          <div className="border-box"/>
                          {item.release_date}
                        </td>
                        <td className="uk-text-left">
                          <div className="border-box"/>
                          {item.drm_pf_information_status.name}
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
              </table>
          }
        </div>
      </div>
      {searchResults?.paging &&
          <>
            <div
              className="uk-text-center mt-5">全{searchResults?.paging.count}件（{searchResults?.paging.page}/{searchResults?.paging.pageCount}）
            </div>
            <div className="pagination-container">
              <a onClick={() => {
                onPageChange(1)
              }}>FIRST</a>
              <ReactPaginate
                forcePage={searchResults?.paging.page - 1} // ReactPaginate→0始まり CakePHP→1始まり
                onPageChange={( selectedItem: { selected: number } ) => onPageChange(selectedItem.selected + 1)}
                pageRangeDisplayed={5}
                pageCount={searchResults?.paging.pageCount}
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                previousLabel='<'
                nextLabel='>'
                breakLabel='...'
              />
              <a onClick={() => {
                onPageChange(searchResults?.paging.pageCount)
              }}>LAST</a>
            </div>
            </>
        }
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  );
}
