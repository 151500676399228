import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SubHeader from "@/components/SubHeader";
import { useLoadingStore } from "@/stores/loading";
import {axios} from '@/lib/axios';
import * as Config from '@/config'
import {useMessageModalStore} from '@/stores/message'

export interface customerInfo {
  id?: number,
  customer_number?: string,
  customer_type_id?: string,
  customer_group_type_id?: string,
  group_name?: string,
  department_name?: string,
  unit_name?: string,
  section_name?: string,
  position?: string,
  last_name?: string,
  first_name?: string,
  reading_last_name?: string,
  reading_first_name?: string,
  phone_number?: string,
  emergency_phone_number?: string,
  zipcode?: string,
  pref?: string,
  city?: string,
  house_number?: string,
  building_name?: string,
  email_address?: string,
  login_id?: string,
  is_personal_information_handling?: string,
  remarks?: string,
  customer_type?: string,
  customer_group_type?: string,
  modified?: string,
  modified_by?: string,
}

export default function BasicInfoDetail() {

  // パラメータ取得
  const currentURL = window.location.href;
  const segments = currentURL.split('/');
  const id = segments[segments.length - 1];

  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  
  const navigate = useNavigate();
  const breadCrumbs = [
    {title: '顧客一覧', path: '/customer'},
    {title: '基本情報'}
  ];
  const onClickEdit = () => {
    navigate(`/customer/basicinfo/edit/${id}`);
  }

  const [customerInfo, setCustomerInfo] = useState<customerInfo[]>([]);
  const [isAgree, setIsAgree] = useState<string | null>(null);
  const [customerNumber, setCustomerNumber] = useState<string | undefined>(undefined);

  //setLoading(true)
  useEffect(() => {
    axios.get('/api/v1/customers/getCustomer', {
      params: {
        id: id,
      }
    })
    .then(result => {
      //console.log(result.data);
      setCustomerInfo(result.data.customer);
      setIsAgree(Config.IS_AGREE[result.data.customer[0]['is_personal_information_handling']]);
      if (result.data.customer[0]['customer_number'] != null) {
        setCustomerNumber(result.data.customer[0]['customer_number']);
      } else {
        setCustomerNumber("");
      }
    }).catch(error => {
      //alert(error.message);
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  console.log("-----customerInfo-----");
  console.log(customerInfo);
 
    return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={customerNumber}/>
        <ul className="uk-tab uk-margin-small-top">
          <li className="uk-active"><Link to={`/customer/basicinfo/detail/${id}`}>基本情報</Link></li>
          <li><Link to={`/customer/drmpf/detail/${id}`}>DRM-PF</Link></li>
          <li><Link to={`/customer/ticket/${id}`}>チケット</Link></li>
          {customerInfo?.map((item, index) => {
            return (
              <div className="right">最終更新：{item.modified_by}&emsp;日時：{item.modified}</div>
              )
          })}
        </ul>
      </div>
      <div className="container uk-margin-large-bottom">
        <div className="uk-text-right customer-attribute-tab">
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickEdit}>編集</button>
        </div>

        {customerInfo?.map((item, index) => {
          return (
            <div className="info-tbl_wrap uk-margin-small-top">
              <table className="info-tbl customer-info-tbl uk-width-1-1">
                <tbody>
                    <tr>
                      <th className="label">顧客番号</th>
                      <td className="content">{item.customer_number}</td>
                    </tr>
                    <tr>
                      <th className="label">問合せ者区分</th>
                      <td className="content">{item.customer_type_id}.{item.customer_type}</td>
                    </tr>
                    <tr>
                      <th className="label">団体区分</th>
                      <td className="content">{item.customer_group_type_id}.{item.customer_group_type}</td>
                    </tr>
                    <tr>
                      <th className="label">法人・団体名</th>
                      <td className="content">{item.group_name}</td>
                    </tr>
                    <tr>
                      <th className="label">部署名・所属</th>
                      <td className="content">{item.department_name}</td>
                    </tr>
                    <tr>
                      <th className="label">課名</th>
                      <td className="content">{item.section_name}</td>
                    </tr>
                    <tr>
                      <th className="label">室名または係名</th>
                      <td className="content">{item.unit_name}</td>
                    </tr>
                    <tr>
                      <th className="label">役職</th>
                      <td className="content">{item.position}</td>
                    </tr>
                    <tr>
                      <th className="label">姓</th>
                      <td className="content">{item.last_name}</td>
                    </tr>
                    <tr>
                      <th className="label">名</th>
                      <td className="content">{item.first_name}</td>
                    </tr>
                    <tr>
                      <th className="label">せい</th>
                      <td className="content">{item.reading_last_name}</td>
                    </tr>
                    <tr>
                      <th className="label">めい</th>
                      <td className="content">{item.reading_first_name}</td>
                    </tr>
                    <tr>
                      <th className="label">電話番号</th>
                      <td className="content">{item.phone_number}</td>
                    </tr>
                    <tr>
                      <th className="label">緊急連絡先（携帯電話等）</th>
                      <td className="content">{item.emergency_phone_number}</td>
                    </tr>
                    <tr>
                      <th className="label">郵便番号</th>
                      <td className="content">{item.zipcode}</td>
                    </tr>
                    <tr>
                      <th className="label">都道府県</th>
                      <td className="content">{item.pref}</td>
                    </tr>
                    <tr>
                      <th className="label">市区町村</th>
                      <td className="content">{item.city}</td>
                    </tr>
                    <tr>
                      <th className="label">町名番地</th>
                      <td className="content">{item.house_number}</td>
                    </tr>
                    <tr>
                      <th className="label">建物名・部屋番号</th>
                      <td className="content">{item.building_name}</td>
                    </tr>
                    <tr>
                      <th className="label">メールアドレス</th>
                      <td className="content">{item.email_address}</td>
                    </tr>
                </tbody>
              </table>
              <table className="customer-info-tbl uk-width-1-1">
                <tbody>
                    <tr>
                      <th className="label">個人情報取扱い</th>
                      <td className="content">{isAgree}</td>
                    </tr>
                    <tr>
                      <th className="label">ログインid</th>
                      <td className="content">{item.login_id}</td>
                    </tr>
                    <tr>
                      <th className="label">パスワード</th>
                      <td className="content">＊＊＊＊＊</td>
                    </tr>
                </tbody>
              </table>
              <table className="customer-info-tbl uk-width-1-1">
                <tbody>
                <tr>
                  <th className="label height-5rem">備考（内部用）</th>
                  <td className="content">{item.remarks}</td>
                </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
}
