import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import SubHeader from "../../components/SubHeader";
import ConfirmModal from "@/components/ConfirmModal";
import {useUserStore} from "@/stores/user";
import * as Common from "@/utils/common";
import * as Config from "@/config";
import {useAdmin} from "@/containers/admin/useAdmin";

export default function Admin() {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const {ADMIN_CONTENTS} = useAdmin(navigate, user)

  const breadCrumbs: { title: string; path?: string }[] = [
    {title: "管理画面"},
  ];

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage]);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="管理画面"/>
      </div>
      <div className="uk-flex uk-flex-center uk-flex-wrap mt-70 px-20">
        {ADMIN_CONTENTS.map((content, index) => (
          <Fragment key={`admin_content_${index}`}>
            {content.showContent &&
                <button
                    className={`${content.style} uk-button-refer uk-margin-left uk-margin-bottom`}
                    onClick={content.onClickFunc}
                >{content.name}
                </button>
            }
          </Fragment>
        ))}
      </div>
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={isShowErrorModal}
          onConfirm={() => {
            setIsShowErrorModal(false);
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
}
