import * as Config from "@/config";
import * as Common from "@/utils/common";
import {User} from "@/stores/user";
import {UserInfo} from "@/containers/admin/Users/types";

export function useUser() {
  type RestrictionKey = keyof typeof Config.RESTRICTION_VALUES;

  interface AdminRestriction {
    title: string;
    name: keyof UserInfo;
    restriction: RestrictionKey;
  }

  const ADMIN_RESTRICTIONS: AdminRestriction[] = [
    {
      title: 'ユーザマスタ',
      name: "restriction_user",
      restriction: Config.RESTRICTION_HISTORY_USER,
    },
    {
      title: 'データインポート',
      name: "restriction_data_import",
      restriction: Config.RESTRICTION_HISTORY_DATA_IMPORT,
    },
    {
      title: 'データエクスポート',
      name: "restriction_data_export",
      restriction: Config.RESTRICTION_HISTORY_DATA_EXPORT,
    },
    {
      title: 'お知らせ管理',
      name: "restriction_manage_information",
      restriction: Config.RESTRICTION_HISTORY_MANAGE_INFORMATION,
    },
    {
      title: '資料管理',
      name: "restriction_document_management",
      restriction: Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT,
    },
    {
      title: '資料収集管理',
      name: "restriction_material_collection_list_management",
      restriction: Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT,
    },
    {
      title: '基盤地図情報管理',
      name: "restriction_basic_map_management",
      restriction: Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT,
    },
    {
      title: 'DICデータ管理',
      name: "restriction_dic_data_management",
      restriction: Config.RESTRICTION_HISTORY_DIC_DATA_MANAGEMENT,
    },
    {
      title: 'DRM-PFポータルサイト管理',
      name: "restriction_portal_management",
      restriction: Config.RESTRICTION_HISTORY_PORTAL_MANAGEMENT,
    },
    {
      title: '郵便番号管理',
      name: "restriction_postal_code_management",
      restriction: Config.RESTRICTION_HISTORY_POSTAL_CODE_MANAGEMENT,
    },
    {
      title: '作図管理',
      name: "restriction_layer_management",
      restriction: Config.RESTRICTION_HISTORY_LAYER_MANAGEMENT,
    },
    {
      title: 'チケット管理',
      name: "restriction_ticket_destination_management",
      restriction: Config.RESTRICTION_HISTORY_TICKET_DESTINATION_MANAGEMENT,
    },
    {
      title: '役職管理',
      name: "restriction_position_management",
      restriction: Config.RESTRICTION_HISTORY_POSITION_MANAGEMENT,
    },
    {
      title: '決裁管理',
      name: "restriction_decision_management",
      restriction: Config.RESTRICTION_HISTORY_DECISION_MANAGEMENT,
    },
  ];

  const loginRestriction = (restrictionCode: any, user?: User, categoryId?: any, tabId?: any) => {
    if (!user) {
      return 0;
    }
    if (user.roll === Config.PERMISSION_ROLL_SYSADMIN) {
      return 2;
    }
    if (categoryId && tabId) {
      return user.user_restrictions.find((restriction) => {
        return restriction.project_category_id === categoryId
          && restriction.category_restriction_code === restrictionCode
          && restriction.tab_id == tabId
      })?.category_restriction_value || 0
    } else if (categoryId) {
      return user.user_restrictions.find((restriction) => {
        return restriction.project_category_id === categoryId
          && restriction.category_restriction_code === restrictionCode
      })?.category_restriction_value || 0
    } else {
      return user.user_restrictions.find((restriction) => {
        return restriction.category_restriction_code === restrictionCode
      })?.category_restriction_value || 0
    }
  }

  return {ADMIN_RESTRICTIONS, loginRestriction};
}