import {LayersControl, TileLayer} from "react-leaflet";
import React, {useEffect, useState} from "react";
import {getLayers} from "@/api/layers";
import {Layer} from "@/containers/admin/Layers/types";
import ConfirmModal from "@/components/ConfirmModal";
import * as Config from "@/config";

export const TileLayers = () => {
  const [layers, setLayers] = useState<Layer[]>([]);
  const [error, setError] = useState<string | null>(null);

  const MAP_PROVIDER = {
    OSM: 'OSM',
    GSI: 'GSI'
  }
  const attributions = {
    [MAP_PROVIDER.OSM]: '© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>',
    [MAP_PROVIDER.GSI]: '© <a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank" rel="noreferrer">国土地理院</a>'
  }
  const tileIdentifier = {
    [MAP_PROVIDER.OSM]: "openstreetmap",
    [MAP_PROVIDER.GSI]: "cyberjapandata"
  }
  const getMapProvider = (url: string) => {
    const provider = Object.entries(tileIdentifier).find(([key, identifier]) => url.includes(identifier));
    return provider ? provider[0] : undefined;
  }

  const getAttribution = (url: string) => {
    const provider = getMapProvider(url);
    return provider ? attributions[provider] : undefined;
  }

  const handleTileError = () => {
    return {tileerror: (error: any) => setError(Config.MESSAGE_NO_E97)};
  };

  useEffect(() => {
    getLayers().then(result => setLayers(result.data.layers));
  }, [])
  return (
    <LayersControl>
      {layers.length == 0 &&
          <LayersControl.BaseLayer checked name="国土地理院　標準地図">
              <TileLayer
                  url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                  attribution={attributions.GSI}
                  {...({} as any)}
              />
          </LayersControl.BaseLayer>
      }
      {layers.map((layer, index) => {
        return (
          <LayersControl.BaseLayer checked={index == 0} name={layer.name}>
            <TileLayer
              url={layer.url}
              eventHandlers={handleTileError()}
              attribution={getAttribution(layer.url)}
              {...({} as any)}
            />
          </LayersControl.BaseLayer>
        )
      })}
      <ConfirmModal
        className={"line-break"}
        text={error ?? ''}
        confirmButtonText="閉じる"
        isShow={!!error}
        onConfirm={() => setError(null)}
      />
    </LayersControl>
  )
}