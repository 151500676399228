import {useLoadingStore} from "@/stores/loading";
import {useProjectStore} from "@/stores/project";
import {CATEGORY_CODE_FAQ, CATEGORY_CODE_SALES_APPLY} from "@/config";
import * as Config from "@/config";
import {SubmitHandler, useFormContext, useWatch} from "react-hook-form";
import React, {useEffect, useMemo} from "react";
import {chooseComponent, SearchRows} from "@/components/ProgressList/SearchRows";

/**
 * 検索
 */
interface SearchProps {
  attributes: Array<any>,
  areas: Array<any>,
  blocks: Array<any>,
  areaName: string,
  blockName: string,
  onSearch: () => void;
  onClear: () => void;
  toggleSearchVisibility: () => void;
  officialPositions: any[]
}

export const SearchContainer = (props: SearchProps) => {
  const setLoading = useLoadingStore(state => state.setLoading);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);

  const isSalesApply = selectedProjectCategory?.category_code === CATEGORY_CODE_SALES_APPLY;
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isApproval = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_APPROVAL;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {errors, isDirty},
  } = useFormContext()
  const selectedArea = useWatch({
    control,
    name: 'area_id',
    defaultValue: '',
  });
  const selectedBlock = useWatch({
    control,
    name: 'block_id',
    defaultValue: '',
  });
  const selectedDecisionUser = useWatch({
    control,
    name: 'approval_status.official_position',
    defaultValue: '',
  });
  const watchApprovalStatus = useWatch({
    control,
    name: 'approval_status',
    defaultValue: '',
  });

  const filteredBlocks = useMemo(() => {
    if (selectedArea) return props.blocks.filter((block: any) => block.area_id === parseInt(selectedArea));
    else return [];
  }, [selectedArea]);

  useEffect(() => {
    if (isDirty) {
      setValue('block_id', '');
    }
  }, [selectedArea]);

  const onSubmit: SubmitHandler<any> = (data) => {
    props.onSearch();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="condition-tbl">
        <tbody>
        {isApproval &&
            <ApprovalSearchContainer {...props} />
        }
        {!isApproval &&
            <>
                <tr className="header">
                    <td colSpan={isSalesApply ? 2 : 4}>{props.areaName}</td>
                  {isSalesApply &&
                      <>
                          <td colSpan={1} className="uk-width-1-3">承認状況</td>
                          <td colSpan={1}>コメント</td>
                      </>
                  }
                </tr>
              {selectedProjectCategory?.category_code !== CATEGORY_CODE_FAQ &&
                  <tr>
                      <td colSpan={isSalesApply ? 2 : 4}>
                          <div className="uk-flex">
                              <div className="uk-inline">
                                  <select
                                      className={`${selectedArea ? '' : 'placeholder'} ${errors.area_id ? "error-form" : ""}`}
                                      defaultValue={""} {...register("area_id")}>
                                      <option value="">エリア選択</option>
                                    {props.areas.map((o: any) => {
                                      return <option key={`progress-list-search-area-${o.id}`}
                                                     value={o.id}>{o.code + ': ' + o.name}</option>
                                    })}
                                  </select>
                                {errors.area_id && <div className="error">{`${errors.area_id.message}`}</div>}
                              </div>
                            {!isBasicMap &&
                                <div className="uk-inline uk-margin-left">
                                    <select
                                        className={`${selectedBlock ? '' : 'placeholder'} ${errors.block_id ? "error-form" : ""}`}
                                        defaultValue={""} {...register("block_id")}>
                                        <option value="">ブロック選択</option>
                                      {filteredBlocks.map((o: any) => {
                                        return <option value={o.id}>{o.code + ': ' + o.name}</option>
                                      })}
                                    </select>
                                  {errors.block_id && <div className="error">{`${errors.block_id.message}`}</div>}
                                </div>
                            }
                          </div>
                      </td>
                    {isSalesApply &&
                        <>
                            <td colSpan={1}>
                                <div className="uk-flex">
                                    <label className="uk-margin-small-right">
                                        <input
                                            type="checkbox"
                                            {...register("approval_status.un_applied")}
                                        />
                                        未申請
                                    </label>
                                    <label className="uk-margin-small-right">
                                        <input
                                            type="checkbox"
                                            {...register("approval_status.un_approved")}
                                        />
                                        未承認
                                    </label>
                                    <label className="uk-margin-small-right">
                                        <input
                                            type="checkbox"
                                            {...register("approval_status.approved")}
                                        />
                                        承認済
                                    </label>
                                    <label className="uk-margin-small-right">
                                        決裁者
                                    </label>
                                    <select defaultValue={""}
                                            className={selectedDecisionUser ? '' : 'placeholder'} {...register("approval_status.official_position")}>
                                        <option value="">未選択</option>
                                      {props.officialPositions?.map((d: any) => {
                                        return <option key={`progress-search-decision-users-${d.id}`}
                                                       value={d.id}>{d.name}</option>
                                      })}
                                    </select>
                                </div>
                            </td>
                            <td colSpan={1}>
                                <div className="uk-flex">
                                    <label className="uk-margin-small-right">
                                        <input
                                            type="checkbox"
                                            {...register("approval_status.has_comment")}
                                        />
                                        あり
                                    </label>
                                    <label className="uk-margin-small-right">
                                        <input
                                            type="checkbox"
                                            {...register("approval_status.no_comment")}
                                        />
                                        なし
                                    </label>
                                </div>
                            </td>
                        </>
                    }
                  </tr>
              }
              <SearchRows attributes={props.attributes}/>
            </>
        }
        <tr>
          <td colSpan={4}>
            <div className="uk-flex uk-flex-center">
              <button type="submit" className="uk-button--m uk-button-refer">検索</button>
              <button type="button" onClick={props.onClear}
                      className="uk-button--m uk-button-cancel uk-margin-left">クリア
              </button>
              <button type="button" onClick={props.toggleSearchVisibility}
                      className="uk-button--m uk-button-cancel uk-margin-left">閉じる
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  )
}

const ApprovalSearchContainer = (props: SearchProps) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {errors, isDirty},
  } = useFormContext()

  const selectedDecisionUser = useWatch({
    control,
    name: 'approval_status.official_position',
    defaultValue: '',
  });

  return (
    <>
      <tr className="header">
        <td colSpan={1}>決裁</td>
        <td colSpan={1} className="uk-width-1-3">承認状況</td>
        <td colSpan={1}>コメント</td>
      </tr>
      <tr>
        <td colSpan={1}>
          {props.attributes[0] && chooseComponent(props.attributes[0])}
        </td>
        <td colSpan={1}>
          <div className="uk-flex">
            <label className="uk-margin-small-right">
              <input
                type="checkbox"
                {...register("approval_status.un_applied")}
              />
              未申請
            </label>
            <label className="uk-margin-small-right">
              <input
                type="checkbox"
                {...register("approval_status.un_approved")}
              />
              未承認
            </label>
            <label className="uk-margin-small-right">
              <input
                type="checkbox"
                {...register("approval_status.approved")}
              />
              承認済
            </label>
            <label className="uk-margin-small-right">
              決裁者
            </label>
            <select defaultValue={""}
                    className={selectedDecisionUser ? '' : 'placeholder'} {...register("approval_status.official_position")}>
              <option value="">未選択</option>
              {props.officialPositions?.map((d: any) => {
                return <option key={`progress-search-decision-users-${d.id}`}
                               value={d.id}>{d.name}</option>
              })}
            </select>
          </div>
        </td>
        <td colSpan={1}>
          <div className="uk-flex">
            <label className="uk-margin-small-right">
              <input
                type="checkbox"
                {...register("approval_status.has_comment")}
              />
              あり
            </label>
            <label className="uk-margin-small-right">
              <input
                type="checkbox"
                {...register("approval_status.no_comment")}
              />
              なし
            </label>
          </div>
        </td>
      </tr>
    </>
  )
}
