import React from "react";
import * as Config from "@/config";
import {useProgressStore} from "@/stores/progress";
import {Attribute} from "@/components/ProgressList/types";
import {SortIndicator} from "@/components/SortIndicator";

interface SharedTableHeadAttributesProps {
  CollectList?: Attribute[];
}

export const SharedTableHeadAttributes = (props: SharedTableHeadAttributesProps) => {
  const [tabs, listAttributes] = useProgressStore(state => [state.tabs, state.listAttributes]);
  const displayAttributes = props.CollectList ? props.CollectList : listAttributes;
  return (
    <>
      {displayAttributes.map((attr: Attribute) => {
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        return (<th key={`list-attribute-${attr.id}`} rowSpan={2} className="sort-indicator-wrap"
                    style={{minWidth: width, maxWidth: width}}>
          <div className="border-box"/>
          {attr.name}
          <SortIndicator item_name={Config.ProgressListSortItem.ATTRIBUTES} column_name={attr.column_name}/>
        </th>)
      })}
    </>
  )
}


export const SharedTableHeadTabNames = () => {
  const [tabs, listAttributes] = useProgressStore(state => [state.tabs, state.listAttributes])

  return (
    <>
      {tabs.map((tab: any) => {
        return (
          <th key={`shared-table-head-tab-names-${tab.id}`} className="uk-padding-remove-horizontal">
            <div className="border-box"/>
            <div className="uk-text-center">{tab.name}</div>
          </th>
        )
      })}
    </>
  )
}

interface SharedTableHeadTabsProps {
  tabs: any[];
  selectedTabId: number | undefined;
  selectedTabStatusesId: number | undefined;
  onSelectedTabStatusesId: (tabStatusesId: number) => void;
  getTabStatusCount: any;
  allTabCounts: any;
  onSelectedTabId: (tabId: number) => void;
}

export const SharedTableHeadTabs = (props: SharedTableHeadTabsProps) => {
  return (
    <>
      {props.tabs.map((tab: any) => {
        return (
          <th className="uk-padding-remove-horizontal">
            <div className="border-box"/>
            <div className="status-box">
              {tab.tab_statuses.map((tabStatus: any) => {
                return (
                  <div className="">{
                    props.selectedTabStatusesId !== tabStatus.id ?
                      <a
                        onClick={() => props.onSelectedTabStatusesId(tabStatus.id)}>{tabStatus.status}：{props.getTabStatusCount(tabStatus.id)}</a>
                      :
                      <span>{tabStatus.status}：{props.getTabStatusCount(tabStatus.id)}</span>
                  }</div>)
              })}
              <div className="">{
                props.selectedTabId !== tab.id ?
                  <a onClick={() => props.onSelectedTabId(tab.id)}>全件：{props.allTabCounts[tab.id]}</a>
                  :
                  <span>全件：{props.allTabCounts[tab.id]}</span>
              }</div>
            </div>
          </th>
        )
      })}
    </>
  )
}
