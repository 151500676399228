import React, {useCallback, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import SubHeader from "@/components/SubHeader";
import {useLoadingStore} from "@/stores/loading";
import {axios} from '@/lib/axios';
import * as Config from '@/config'
import {useMessageModalStore} from '@/stores/message'
import MessageList from "@/components/Ticket/MessageList";
import MessageInputBox from "@/components/Ticket/MessageInputBox";
import {Ticket, TicketDestinations} from "@/components/Ticket/types";
import {customerInfo} from "@/containers/customer/Ticket/types";
import {getTicket as getTicketApi} from "@/api/ticket";
import {getTicketDestinationBy} from "@/api/ticketDestination";

export default function CustomerTicket() {
  const params = useParams();
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const breadCrumbs = [
    {title: '顧客一覧', path: '/customer'},
    {title: 'チケット'}
  ];
  const id = params.customerId;

  const [customerInfo, setCustomerInfo] = useState<customerInfo[]>([]);
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [ticket, setTicket] = useState<Ticket>();

  const [ticketDestList, setTicketDestList] = useState<TicketDestinations[]>(
    []
  );
  const modifiedBy = `${customerInfo?.[0]?.modified_by ?? ''}`;
  const modified = `${customerInfo?.[0]?.modified ?? ''}`;

  const getTicket = useCallback(() => {
    Promise.all([
      getTicketApi({customer_id: Number(params.customerId)}),
      getTicketDestinationBy(Config.PROJECT_ID_BILLING),
    ])
      .then((result) => {
        setTicket(result[0].data);
        setTicketDestList(result[1].data.ticketDestinations);
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.dataId, setLoading, setMessage]);

  useEffect(() => {
    getTicket();
    axios.get('/api/v1/customers/getCustomer', {
      params: {
        id: id,
      }
    })
      .then(result => {
        setCustomerInfo(result.data.customer);
        if (result.data.customer[0]['customer_number'] != null) {
          setCustomerNumber(result.data.customer[0]['customer_number']);
        }
      }).catch(() => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title={customerNumber}/>
        <ul className="uk-tab uk-margin-small-top">
          <li><Link to={`/customer/basicinfo/detail/${id}`}>基本情報</Link></li>
          <li><Link to={`/customer/drmpf/detail/${id}`}>DRM-PF</Link></li>
          <li className="uk-active"><Link to={`/customer/ticket/${id}`}>チケット</Link></li>
          <div className="right">最終更新：{modifiedBy}&emsp;日時：{modified}</div>
        </ul>
      </div>
      <div className="container uk-margin-large-bottom">
        <div>
          <MessageList ticket={ticket} className="uk-margin-top"/>
          <MessageInputBox
            ticket={ticket}
            getTicket={getTicket}
            ticketDestList={ticketDestList}
            customer={customerInfo[0]}
          />
        </div>
      </div>
    </div>
  );
}
