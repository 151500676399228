import clipMIcon from "@/assets/images/clip_m.png";
import React from "react";
import {TicketComment} from "@/containers/data/Public/Public";
import {CustomMarkdown} from "@/components/CustomMarkdown";
import {useUserStore} from "@/stores/user";
import {Ticket} from "@/components/Ticket/types";

interface Props {
  ticket?: Ticket;
  className?: string;
  ticketComments?: TicketComment[];
}

export default function MessageList({
                                      ticket,
                                      className,
                                      ticketComments,
                                    }: Props) {
  const comments = ticket ? ticket.data_ticket_comments : ticketComments ?? [];
  return (
    <div className={`messages ${className}`}>
      {comments?.map((item, index) => (
        <Comment
          key={`frontend-ticket-message-list-${index}`}
          item={item}
          isInquiryUser={item.is_inquiry_user}
          ticket={ticket}
        />
      ))}
    </div>
  );
}

interface CommentProps {
  ticket?: Ticket;
  item: TicketComment;
  isInquiryUser: boolean;
}

const Comment = ({ticket, item, isInquiryUser}: CommentProps) => {
  const user = useUserStore((state) => state.user);
  const isCreatedMe = user?.id == item.created_by && !isInquiryUser;
  const messageType = isCreatedMe ? "sent" : "received";
  const messageSentInfo = () => {
    if (isInquiryUser) {
      return `${item.created_group_by} ${item.created_user_by}`
    }
    // 送信先
    const destination = (() => {
      if (!item.ticket_destinations) {
        return `${ticket?.inquiry_company_name ?? ""} ${ticket?.inquiry_user_name ?? ""}`;
      }
      return item.ticket_destinations?.name ?? " ";
    })();
    // 送信元
    const sender = `${item.created_group_by} ${item.created_user_by}`;

    return `送信先：${destination} 送信元：${sender}`;
  }

  return (
    <div>
      <div className={`${messageType}-message`}>
        <div className={`${messageType}-message-name`}>{messageSentInfo()}</div>
        <div className={`${messageType}-message-box`}>
          <div>
            <CustomMarkdown content={item.comment}/>
          </div>
          {item.attached_files.length > 0 && (
            <>
              <hr/>
              {item.attached_files.map((file, index) => (
                <div key={index} className="uk-flex uk-flex-middle">
                  <img
                    src={clipMIcon}
                    width={16}
                    height={16}
                    className="uk-margin-small-right"
                  />
                  <a href={file.filepath} target="_blank">
                    {file.original_filename}
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={`${messageType}-message-time`}>{item.created}</div>
      </div>
    </div>
  );
};
