import {axios} from "@/lib/axios";

export const getLayers = () => {
  return axios.get(`/api/v1/layers/getLayers`);
}

export const deleteLayer = (id: number) => {
  return axios.post(`/api/v1/layers/deleteLayer`, {id});
}

export const postLayer = (name: string, url: string) => {
  return axios.post(`/api/v1/layers/postLayer`, {name, url})
}