import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import {SubmitHandler, useForm, useFieldArray, FieldError} from "react-hook-form";
import {useLoadingStore} from "@/stores/loading";
import {TextBox} from "@/components/Form/TextBox";
import ConfirmModal from "@/components/ConfirmModal";
import {createUser, getDuplicateData, getLoginUserInfo, getUserData, updateUser} from "@/api/users";
import {TextArea} from "@/components/Form/TextArea";
import {RadioButton} from "@/components/Form/RadioButton";
import {User, useUserStore} from "@/stores/user";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import {UserInfo} from "@/containers/admin/Users/types";
import {axios} from '@/lib/axios'
import {OfficialPosition, UserOfficialPosition} from '@/api/approverManagement';
import {useUser} from "@/containers/admin/Users/useUser";


export default function UserEdit() {
  const location = useLocation();
  const isEdit = location.pathname.includes('/edit');
  const isDuplicate = location.pathname.includes('/duplicate');
  const isNew = location.pathname.includes('/new');
  const navigate = useNavigate();
  const userId = useParams().id;
  const setLoading = useLoadingStore(state => state.setLoading);
  const [user, setUser] = useUserStore(state => [state.user, state.setUser]);
  const canUser = Common.canUserManagement(user)
  const {ADMIN_RESTRICTIONS, loginRestriction} = useUser();

  const title = () => {
    if (isNew) {
      return "新規作成"
    } else if (isEdit) {
      return "編集"
    } else {
      return "複製"
    }
  }

  const breadCrumbs = [
    {title: '管理画面', path: '/admin'},
    {title: 'ユーザ一覧', path: '/admin/users'},
    {title: title()}
  ];

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [officialPositions, setOfficialPositions] = useState<OfficialPosition[]>();
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
  const [pfPasswordHide, setPfPasswordHide] = useState(true);
  const [canOfficialPosition, setCanOfficialPosition] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    formState: {errors}
  } = useForm<UserInfo>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: userInfo,
      values: userInfo,
    }
  );
  const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
    control,
    name: "user_official_positions",
  });
  const hasErrors = Object.keys(errors).length > 0;

  const onClickCancel = () => {
    setIsShowBackModal(true);
  }

  const onClickSave = () => {
    handleSubmit(onSubmit)();
  }

  const onAddPosition = () => {
    append({})
    setCanOfficialPosition(false)
  }

  const onSubmit: SubmitHandler<UserInfo> = async (data) => {
    setEmailErrorMessage(null);
    setLoading(true)

    const lastElementIndex = data.user_official_positions.length - 1;
    if (data.user_official_positions[lastElementIndex].official_position_id != '') {
      const positionResult = await axios.post("/api/v1/approverManagement/postUserOfficialPosition", {
        ...data.user_official_positions[lastElementIndex] as UserOfficialPosition,
        user_id: userId
      }).then(result => {
        return true;
      }).catch(error => {
        console.log(error)

        if (error?.response?.data?.message) {
          const message = error.response.data.message;
          if (Object.keys(message).length > 0) {
            Object.entries(message).forEach(([key, value]) => {
              const typeKey = key as keyof UserOfficialPosition;
              const typeValue = value as string;
              setError(`user_official_positions.${lastElementIndex}.${typeKey}`, {
                type: "server", message: typeValue
              })
            });
          } else {
            setErrorMessage(Config.MESSAGE_NO_E37);
          }
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
        setLoading(false);
        return false;
      });
      if (!positionResult) return;
    }

    const response = isEdit ? updateUser(data) : createUser(data)
    response.then(result => {
      getLoginUserInfo().then(result => {
        setUser(result.data)
      }).finally(() => {
        navigate(-1)
        setLoading(false);
      })
    }).catch(error => {
      const res = error.response;
      if (res) {
        if (res.data['email_address']) {
          setEmailErrorMessage(res.data['email_address']);
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
      } else {
        setErrorMessage(Config.MESSAGE_NO_E37);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if (canUser < Config.RESTRICTION_HISTORY_USER_OK.value) {
      navigate('/admin');
    }
    setLoading(true)
    const requests = [
      axios.get("/api/v1/approverManagement/getOfficialPositions"),
    ];
    if (isEdit) {
      requests.push(getUserData(userId));
      requests.push(axios.get("/api/v1/approverManagement/getUserOfficialPosition", {
        params: {user_id: userId}
      }));
    } else if (isDuplicate) {
      requests.push(getDuplicateData(userId));
      requests.push(axios.get("/api/v1/approverManagement/getUserOfficialPosition", {
        params: {user_id: userId}
      }));
    } else {
      requests.push(getUserData());
    }

    Promise.all(requests).then((response) => {
      setOfficialPositions(response[0].data.official_positions);
      const userInfo = response[1].data;
      if (response[2] && response[2].data.user_official_positions.length > 0) {
        userInfo.user_official_positions = response[2].data.user_official_positions;
        setCanOfficialPosition(true);
      } else {
        userInfo.user_official_positions = [{}];
      }
      setUserInfo(userInfo);

    }).catch(error => {
      setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  const watchedProjects = watch('projects', []);
  const watchedRoll = watch('roll');

  const isAtLeastOneChecked = () => {
    return watchedProjects.some(project => project.selected) || Config.MESSAGE_NO_E08;
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="ユーザ管理" showPrompt/>
        {isEdit &&
            <div className="uk-text-right uk-margin-small-bottom">
                最終更新ユーザ：{userInfo?.last_updated_by} 最終更新:{userInfo?.last_updated_at}
            </div>
        }
        {hasErrors && <div className="error-box">入力エラーがあります</div>}
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div className="precaution">※は必須です</div>
          <div>
            <button className="uk-button--m uk-button-cancel" onClick={onClickCancel}>キャンセル</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
          </div>
        </div>
      </div>
      {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}
      <div className="container">
        <form>
          <div className="admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tr>
                <td>メールアドレス<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="email_address"
                      register={register}
                      rules={{
                        required: Config.MESSAGE_NO_E08,
                        maxLength: {value: 254, message: Config.MESSAGE_NO_E35(254)},
                      }}
                      errors={errors}
                      placeholder={"メールアドレスを入力してください"}
                    />
                  </div>
                  {errors.email_address && <p className="precaution">{errors.email_address.message}</p>}
                  {emailErrorMessage && <p className="precaution">{emailErrorMessage}</p>}
                </td>
              </tr>
              <tr>
                <td>姓名<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="uk-flex gap-10 width-40p">
                    <div className="uk-flex-1">
                      <TextBox
                        name="last_name"
                        register={register}
                        rules={{
                          required: Config.MESSAGE_NO_E08,
                          maxLength: {value: 30, message: Config.MESSAGE_NO_E35(30)},
                        }}
                        errors={errors}
                        placeholder={"姓を入力してください"}
                      />
                    </div>
                    <div className="uk-flex-1">
                      <TextBox
                        name="first_name"
                        register={register}
                        rules={{maxLength: {value: 30, message: Config.MESSAGE_NO_E35(30)},}}
                        errors={errors}
                        placeholder={"名を入力してください"}
                      />
                    </div>
                  </div>
                  {errors.last_name && <p className="precaution">{errors.last_name.message}</p>}
                  {errors.first_name && <p className="precaution">{errors.first_name.message}</p>}
                </td>
              </tr>
              <tr>
                <td>プロジェクト<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="uk-grid-small uk-grid">
                    {watchedProjects.map((project, index) => (
                      <label key={index}>
                        <div>
                          <input
                            type="checkbox"
                            {...register(
                              `projects.${index}.selected`,
                              {validate: isAtLeastOneChecked,}
                            )}
                          />
                        </div>
                        {project.name}
                      </label>
                    ))}
                  </div>
                  {watchedProjects.find((project, index) => errors.projects?.[index]?.selected) &&
                      <p className="precaution">{Config.MESSAGE_NO_E08}</p>
                  }
                </td>
              </tr>
              <tr>
                <td>グループ<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">
                    <select
                      {...register("selected_group")}
                    >
                      {userInfo?.groups?.map((group, index) => (
                        <option key={index} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>DRM-PF ログインID</td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="drm_pf_login_id"
                      register={register}
                      rules={{maxLength: {value: 30, message: Config.MESSAGE_NO_E35(30)},}}
                      errors={errors}
                      placeholder={"DRM-PF　ログインIDを入力してください"}
                    />
                  </div>
                  {errors.drm_pf_login_id && <p className="precaution">{errors.drm_pf_login_id.message}</p>}
                </td>
              </tr>
              <tr>
                <td>DRM-PF パスワード</td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex uk-position-relative">
                    <TextBox
                      name="drm_pf_password"
                      register={register}
                      inputType={pfPasswordHide ? "password" : "text"}
                      rules={{
                        maxLength: {value: 16, message: Config.MESSAGE_NO_E35(16)},
                        minLength: {value: 6, message: Config.MESSAGE_NO_E67(6)},
                        pattern: {value: /^[a-zA-Z0-9_-]+$/, message: Config.MESSAGE_NO_E03}
                      }}
                      errors={errors}
                      placeholder={"DRM-PF　パスワードを入力してください"}
                      autocomplete="new-password"
                    />
                    <button
                      id="buttonEye"
                      type="button"
                      className={
                        pfPasswordHide
                          ? "uk-form-icon uk-form-icon-flip fa fa-eye"
                          : "uk-form-icon uk-form-icon-flip fa fa-eye-slash"
                      }
                      onClick={() => setPfPasswordHide(!pfPasswordHide)}
                    ></button>
                  </div>
                  {errors.drm_pf_password && <p className="precaution">{errors.drm_pf_password.message}</p>}
                </td>
              </tr>
              <tr>
                <td>作成日</td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">{isEdit ? userInfo?.created : "自動取得"}</div>
                  {errors.expiration_date && <p className="precaution">{errors.expiration_date.message}</p>}
                </td>
              </tr>
              <tr>
                <td>有効期限<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">
                    <input
                      type="date"
                      {...register("expiration_date", {
                        required: Config.MESSAGE_NO_E08,
                      })}
                      placeholder="有効期限を入力してください"
                    />
                  </div>
                  {errors.expiration_date && <p className="precaution">{errors.expiration_date.message}</p>}
                </td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td colSpan={3}>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="phone_number"
                      register={register}
                      rules={{maxLength: {value: 21, message: Config.MESSAGE_NO_E35(21)},}}
                      errors={errors}
                      placeholder={"電話番号を入力してください"}
                    />
                  </div>
                  {errors.phone_number && <p className="precaution">{errors.phone_number.message}</p>}
                </td>
              </tr>
              <tr>
                <td>備考</td>
                <td colSpan={3}>
                  <TextArea
                    name="remarks"
                    register={register}
                    rules={{
                      maxLength: {value: 200, message: Config.MESSAGE_NO_E36},
                    }}
                    errors={errors}
                    placeholder={"備考を入力してください"}
                  />
                </td>
                {errors.remarks && <p className="precaution">{errors.remarks.message}</p>}
              </tr>
              <tr>
                <td>権限<span className="error">※</span></td>
                <td colSpan={3}>
                  <div className="uk-grid-small uk-grid">
                    <RadioButton
                      name="roll"
                      options={Config.PERMISSION_ROLL}
                      register={register}
                      rules={{required: Config.MESSAGE_NO_E08}}
                      errors={errors}
                      roll={userInfo?.roll}
                      loginRoll={user?.roll}
                    />
                  </div>
                  {errors.roll && <p className="precaution">{errors.roll.message}</p>}
                </td>
              </tr>
              {fields.map((field, index) => {
                const lastIndex = fields.length - 1 === index;
                return (
                  <tr key={field.id}>
                    <td>役職</td>
                    <td>
                      <div className="uk-flex">
                        <select
                          {...register(`user_official_positions.${index}.official_position_id`)}
                          disabled={!lastIndex}
                        >
                          <option value="">選択してください</option>
                          {officialPositions?.map((officialPosition, index) => (
                            <option key={index} value={officialPosition.id}>
                              {officialPosition.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.user_official_positions?.[index]?.official_position_id &&
                          <p className="precaution">{errors.user_official_positions?.[index]?.official_position_id?.message}</p>}
                    </td>
                    <td className="table-header-background">期間</td>
                    <td>
                      <div className="uk-flex uk-flex-middle">
                        <input
                          type="date"
                          {...register(`user_official_positions.${index}.applicable_start_date`)}
                          disabled={!lastIndex}
                        />
                        〜
                        <input
                          type="date"
                          {...register(`user_official_positions.${index}.applicable_end_date`)}
                          disabled={!lastIndex}
                        />
                      </div>
                      {errors.user_official_positions?.[index]?.applicable_start_date &&
                          <p className="precaution">{errors.user_official_positions?.[index]?.applicable_start_date?.message}</p>}
                      {errors.user_official_positions?.[index]?.applicable_end_date &&
                          <p className="precaution">{errors.user_official_positions?.[index]?.applicable_end_date?.message}</p>}
                    </td>
                  </tr>
                )
              })}
            </table>
            {canOfficialPosition &&
                <button
                    type="button"
                    className="uk-button--m uk-button-cancel uk-margin-small-top"
                    onClick={onAddPosition}>
                    追加
                </button>
            }
          </div>
          {watchedRoll === Config.PERMISSION_ROLL_ADMIN && (
            <div className="admin-tbl uk-margin-top">
              <table className="uk-width-1-1">
                <tr>
                  <td className="width-100" colSpan={2}>管理画面 操作制限</td>
                </tr>
                {ADMIN_RESTRICTIONS.map((restriction) => (
                  <tr key={restriction.name}>
                    <td>{restriction.title}<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name={restriction.name}
                          options={Config.RESTRICTION_VALUES[restriction.restriction]}
                          register={register}
                          rules={{required: Config.MESSAGE_NO_E08}}
                          errors={errors}
                          loginRestriction={loginRestriction(restriction.restriction, user)}
                        />
                      </div>
                      {errors[restriction.name] && (
                        <p className="precaution">{(errors[restriction.name] as FieldError)?.message}</p>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
          <div className="admin-tbl uk-margin-top">
            <table className="uk-width-1-1">
              {(watchedRoll === Config.PERMISSION_ROLL_ADMIN || watchedRoll === Config.PERMISSION_ROLL_USER) && (
                <>
                  <tr>
                    <td className="width-100" colSpan={2}>顧客情報</td>
                  </tr>
                  <tr>
                    <td>顧客情報<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name={"restriction_billing_list"}
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_BILLING_LIST]}
                          register={register}
                          rules={{required: Config.MESSAGE_NO_E08}}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_BILLING_LIST, user)}
                        />
                      </div>
                      {errors.restriction_billing_list &&
                          <p className="precaution">{errors.restriction_billing_list?.message}</p>}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
          {watchedProjects.map((project, projectIndex) => {
            return project.selected && watchedRoll !== Config.PERMISSION_ROLL_SYSADMIN && (
              <div key={projectIndex}>
                {project.categories.map((category, categoryIndex) => {
                  const watchedRestrictionProgressNew = watch(`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_new`)
                  return (
                    <div className="admin-tbl uk-margin-top uk-margin-bottom" key={categoryIndex}>
                      <table className="uk-width-1-1">
                        <tr>
                          <td className="width-100" colSpan={2}>{project.name} - {category.name}</td>
                        </tr>
                        <tr>
                          <td className="width-100" colSpan={2}>進捗一覧画面</td>
                        </tr>
                        <tr>
                          <td>新規作成・複製<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_new`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PROGRESS_NEW]}
                                register={register}
                                rules={{required: Config.MESSAGE_NO_E08}}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PROGRESS_NEW, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_new &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_new?.message}</p>}
                          </td>
                        </tr>
                        <tr>
                          <td>削除<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_del`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PROGRESS_DEL]}
                                register={register}
                                rules={{required: Config.MESSAGE_NO_E08}}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PROGRESS_DEL, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_del &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_del?.message}</p>}
                          </td>
                        </tr>
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}承認画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}承認画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_approve`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_APPROVE]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_APPROVE, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_approve &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_approve?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="width-100" colSpan={2}>{'\u3000\u3000'}属性画面<span
                            className="error">※「新規作成・複製」権限がある場合、先頭のタブの「閲覧・編集」権限を付与すること</span></td>
                        </tr>
                        {category.tabs.map((tab, tabIndex) => {
                          return (
                            <tr key={tabIndex}>
                              <td>{'\u3000\u3000'}{tab.name}<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.tabs.${tabIndex}.restriction_attributes_tab`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    setValue={setValue}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB, user, category.id, tab.id)}
                                    watchedRestrictionProgressNew={tabIndex === 0 && watchedRestrictionProgressNew}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.tabs?.[tabIndex]?.restriction_attributes_tab &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.tabs?.[tabIndex]?.restriction_attributes_tab?.message}</p>}
                              </td>
                            </tr>
                          )
                        })}
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}申込エリア画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}申込エリア画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_apply_area`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_APPLY_AREA]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_APPLY_AREA, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_apply_area &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_apply_area?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {(category.code === Config.CATEGORY_CODE_SALES_APPLY || category.code === Config.CATEGORY_CODE_FAQ) && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}チケット画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}チケット画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_ticket`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_TICKET]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_TICKET, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_ticket &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_ticket?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}帳票画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}帳票画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_form`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_FORM]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_FORM, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_form &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_form?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {category.code === Config.CATEGORY_CODE_FAQ && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}公開画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}公開画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_public`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PUBLIC]}
                                    register={register}
                                    rules={{required: Config.MESSAGE_NO_E08}}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PUBLIC, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_public &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_public?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="width-100" colSpan={2}>{'\u3000\u3000'}ファイル画面</td>
                        </tr>
                        <tr>
                          <td>{'\u3000\u3000'}ファイル画面<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_file`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_FILE]}
                                register={register}
                                rules={{required: Config.MESSAGE_NO_E08}}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_FILE, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_file &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_file?.message}</p>}
                          </td>
                        </tr>
                      </table>
                    </div>)
                })}
              </div>
            )
          })}
        </form>
      </div>
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          navigate(-1)
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}