import React, { useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import SubHeader from '@/components/SubHeader';
import Attributes from '@/containers/data/Attributes/Attributes'
import Files from '@/containers/data/Files/Files'
import * as Config from '@/config'
import * as Common from '@/utils/common'
import { ProjectCategory, useProjectStore } from '@/stores/project'
import { axios } from '@/lib/axios'
import { useLoadingStore } from '@/stores/loading'
import moment from 'moment'
import ApplyAreas from '@/containers/data/ApplyAreas/ApplyAreas';
import { useInterval } from '@/utils/UseInterval'
import Ticket from "@/containers/data/Ticket/Ticket";
import Public from "@/containers/data/Public/Public";
import Approve from "@/containers/data/Approve/Approve";
import Forms from "@/containers/data/Form/Forms";
import { User, useUserStore } from '@/stores/user'
import { Coordinates } from '@dnd-kit/core/dist/types/coordinates'
import { useMessageModalStore } from '@/stores/message'
import DataTicket from "@/containers/data/Ticket/Ticket";

export interface DataProps {
  isType: Config.Data
}

export default function Data( props: DataProps ) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const user = useUserStore(state => state.user);
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory
  ]);
  const [data, setData] = useState<any | undefined>(undefined);
  const [link, setLink] = useState<string>("");

  const breadCrumbs = ( isType: Config.Data ) => {
    switch (isType) {
      case Config.Data.AttributeNew:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '新規作成' }
        ];
      case Config.Data.AttributeEdit:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '属性', path: Common.attributesDetailUrl(params.projectCategoryId, params.dataId) },
          { title: '編集' }
        ];
      case Config.Data.AttributeCopy:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '属性', path: Common.attributesDetailUrl(params.projectCategoryId, params.dataId) },
          { title: '複製' }
        ];
      case Config.Data.AttributeDetail:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '属性' }
        ];
      case Config.Data.ApplyAreaList:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '申込エリア' }
        ];
      case Config.Data.ApplyAreaListEdit:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '申込エリア', path: Common.applyAreaListUrl(params.projectCategoryId, params.dataId) },
          { title: '編集' }
        ];
      case Config.Data.FileNew:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: 'ファイル' },
          { title: '新規作成' }
        ];
      case Config.Data.FileEdit:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: 'ファイル', path: Common.filesListUrl(params.projectCategoryId, params.dataId) },
          { title: '編集' }
        ];
      case Config.Data.FileList:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: 'ファイル' }
        ];
      case Config.Data.Ticket:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: 'チケット' }
        ];
      case Config.Data.PublicDetail:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '公開' }
        ];
      case Config.Data.PublicEdit:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '公開', path: Common.publicDetailUrl(params.projectCategoryId, params.dataId) },
          { title: '編集' }
        ];
      case Config.Data.ApproveDetail:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '承認' }
        ];
      case Config.Data.ApproveNew:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '承認' },
          { title: '新規作成' }
        ];
      case Config.Data.ApproveEdit:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '承認', path: Common.approveDetailUrl(params.projectCategoryId, params.dataId) },
          { title: '編集' }
        ];
      case Config.Data.ApproveCopy:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '承認', path: Common.approveDetailUrl(params.projectCategoryId, params.dataId) },
          { title: '複製' }
        ];
      case Config.Data.Form:
        return [
          { title: '進捗一覧', path: '/progress' },
          { title: '帳票' }
        ];
    }
    return [];
  }

  function getStatus( dataId: any ) {
    axios.get('/api/v1/datas/status', {
      params: {
        data_id: dataId,
        is_type: Config.tab(props.isType)
      }
    }).then(result => {
      setData(result.data.data);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    });
  }

  function getLinkAttributeValue( dataId: any ) {
    axios.get(`/api/v1/dataAttributeValues/linkAttributeValue/${dataId}`).then(result => {
      setLink(result.data.dataAttributeValues?.attribute_value??"");
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    });
  }

  useEffect(() => {
    if (params.dataId && !Config.isAttributeNew(props.isType)) {
      getStatus(params.dataId);
      getLinkAttributeValue(params.dataId);
    }
  }, [location.pathname]);

  const delay = params.dataId && !Config.isAttributeNew(props.isType) ? 60 * 1000 : null;
  useInterval(() => {
    getStatus(params.dataId);
  }, delay);

  const renderTabs = () => {
    switch (selectedProjectCategory?.category_code) {
      case Config.CATEGORY_CODE_HISTORY:
        return (<HistoryTabs isType={props.isType} dataFiles={data?.data_files ?? []}/>);
      case Config.CATEGORY_CODE_SALES_APPLY:
        return (<ApplyTabs isType={props.isType} dataFiles={data?.data_files ?? []}/>);
      case Config.CATEGORY_CODE_FAQ:
        return (<FaqTabs isType={props.isType} dataFiles={data?.data_files ?? []}/>);
      case Config.CATEGORY_CODE_APPROVAL:
        return (<ApprovalTabs isType={props.isType} dataFiles={data?.data_files ?? []}/>);
      default:
        return null;
    }
  };

  const renderSubPage = () => {
    switch (Config.tab(props.isType)) {
      case Config.Tab.Attribute:
        return <Attributes isType={props.isType} data={data}/>;
      case Config.Tab.ApplyArea:
        return <ApplyAreas isType={props.isType}/>;
      case Config.Tab.File:
        return <Files isType={props.isType}/>;
      case Config.Tab.Ticket:
        return <DataTicket/>
      case Config.Tab.Public:
        return <Public isType={props.isType}/>
      case Config.Tab.Approve:
        return <Approve isType={props.isType}/>
      case Config.Tab.Form:
        return <Forms/>
    }
  };

  return (
    <div>
      {selectedProjectCategory &&
          <>
              <div id="dataSubHeader" className="sub-header">
                  <SubHeader breadCrumbs={breadCrumbs(props.isType)}
                             title={`${selectedProjectCategory?.name || ''}　${link}`}/>
                  <ul className="uk-tab uk-margin-small-top">
                    {renderTabs()}
                    {!Config.isAttributeNew(props.isType) && data &&
                        <div className="right">
                          {data.modified_user && `最終更新ユーザ：${data.modified_user.last_name}${data.modified_user.first_name}`} 最終更新:{moment(data.modified).format(Config.DATE_FORMAT)}
                        </div>
                    }
                  </ul>
              </div>
            {renderSubPage()}
          </>
      }
    </div>
  );
}

// 追加必要な際に都度作成
interface TabsProps {
  isType: Config.Data,
  dataFiles: any[],
}

const HistoryTabs = ( props: TabsProps ) => {
  const user = useUserStore(state => state.user);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory
  ]);
  const params = useParams();
  const attributeLink = Config.isAttributeNew(props.isType) ? "" : Common.attributesDetailUrl(params.projectCategoryId, params.dataId);
  const canFile = Common.canFile(selectedProjectCategory?.id, user);
  const fileLink = Config.isAttributeNew(props.isType) ? "" : Common.filesListUrl(params.projectCategoryId, params.dataId);
  const isBasicMap = Number(params.projectCategoryId) === Config.PROJECT_CATEGORY_ID_BASIC_MAP;

  return (
    <>
      {Common.canShowAttribute(selectedProjectCategory?.id, user) &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Attribute ? "uk-active" : ""}`}>
              <Link to={attributeLink} className={attributeLink === "" ? "disabled-link" : ""}>属性</Link>
          </li>
      }
      {!isBasicMap && canFile > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.File ? "uk-active" : ""}`}>
              <Link to={fileLink} className={fileLink === "" ? "disabled-link" : ""}>ファイル</Link>
          </li>
      }
    </>
  )
}

const ApplyTabs = ( props: TabsProps ) => {
  const user = useUserStore(state => state.user);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory
  ]);
  const params = useParams();
  const attributeLink = Config.isAttributeNew(props.isType) ? "" : Common.attributesDetailUrl(params.projectCategoryId, params.dataId);
  const applyLink = Common.applyAreaListUrl(params.projectCategoryId, params.dataId);
  const canFile = Common.canFile(selectedProjectCategory?.id, user);
  const fileLink = Config.isAttributeNew(props.isType) ? "" : Common.filesListUrl(params.projectCategoryId, params.dataId);
  const approveLink = Common.approveDetailUrl(params.projectCategoryId, params.dataId);
  const formLink = Common.formsUrl(params.projectCategoryId, params.dataId);
  const canTicket = Common.canTicket(selectedProjectCategory?.id, user);
  const ticketUrl = Common.ticketUrl(params.projectCategoryId, params.dataId);
  const canApprove = Common.canApprove(selectedProjectCategory?.id, user);
  const canApplyArea = Common.canApplyArea(selectedProjectCategory?.id, user);
  const canForm = Common.canForm(selectedProjectCategory?.id, user);

  return (
    <>
      {canApprove > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
        selectedProjectCategory?.category_code == Config.CATEGORY_CODE_SALES_APPLY &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Approve ? "uk-active" : ""}`}>
              <Link to={approveLink} className={approveLink === "" ? "disabled-link" : ""}>承認</Link>
          </li>
      }
      {Common.canShowAttribute(selectedProjectCategory?.id, user) &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Attribute ? "uk-active" : ""}`}>
              <Link to={attributeLink} className={attributeLink === "" ? "disabled-link" : ""}>属性</Link>
          </li>
      }
      {canApplyArea > Config.RESTRICTION_HISTORY_APPLY_AREA_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.ApplyArea ? "uk-active" : ""}`}>
              <Link to={applyLink} className={applyLink === "" ? "disabled-link" : ""}>申込エリア</Link>
          </li>
      }
      {canTicket > Config.RESTRICTION_HISTORY_TICKET_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Ticket ? "uk-active" : ""}`}>
              <Link to={ticketUrl} className={ticketUrl === "" ? "disabled-link" : ""}>チケット</Link>
          </li>
      }
      {canForm > Config.RESTRICTION_HISTORY_FORM_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Form ? "uk-active" : ""}`}>
              <Link to={formLink} className={formLink === "" ? "disabled-link" : ""}>帳票</Link>
          </li>
      }
      {canFile > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.File ? "uk-active" : ""}`}>
              <Link to={fileLink} className={fileLink === "" ? "disabled-link" : ""}>ファイル</Link>
          </li>
      }
    </>
  )
}
const FaqTabs = ( props: TabsProps ) => {
  const user = useUserStore(state => state.user);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory
  ]);
  const params = useParams();
  const attributeLink = Config.isAttributeNew(props.isType) ? "" : Common.attributesDetailUrl(params.projectCategoryId, params.dataId);
  const ticketUrl = Common.ticketUrl(params.projectCategoryId, params.dataId);
  const publicLink = Common.publicDetailUrl(params.projectCategoryId, params.dataId);
  const canTicket = Common.canTicket(selectedProjectCategory?.id, user);
  const canPublic = Common.canPublic(selectedProjectCategory?.id, user);
  const canFile = Common.canFile(selectedProjectCategory?.id, user);
  const fileLink = Config.isAttributeNew(props.isType) ? "" : Common.filesListUrl(params.projectCategoryId, params.dataId);

  return (
    <>
      {Common.canShowAttribute(selectedProjectCategory?.id, user) &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Attribute ? "uk-active" : ""}`}>
              <Link to={attributeLink} className={attributeLink === "" ? "disabled-link" : ""}>属性</Link>
          </li>
      }
      {canTicket > Config.RESTRICTION_HISTORY_TICKET_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Ticket ? "uk-active" : ""}`}>
              <Link to={ticketUrl} className={ticketUrl === "" ? "disabled-link" : ""}>チケット</Link>
          </li>
      }
      {canPublic > Config.RESTRICTION_HISTORY_PUBLIC_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Public ? "uk-active" : ""}`}>
              <Link to={publicLink} className={publicLink === "" ? "disabled-link" : ""}>公開</Link>
          </li>
      }
      {canFile > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.File ? "uk-active" : ""}`}>
              <Link to={fileLink} className={fileLink === "" ? "disabled-link" : ""}>ファイル</Link>
          </li>
      }
    </>
  )
}

const ApprovalTabs = ( props: TabsProps ) => {
  const user = useUserStore(state => state.user);
  const [selectedProjectCategory] = useProjectStore(state => [
    state.projectCategory
  ]);
  const params = useParams();
  const canFile = Common.canFile(selectedProjectCategory?.id, user);
  const fileLink = Config.isAttributeNew(props.isType) ? "" : Common.filesListUrl(params.projectCategoryId, params.dataId);
  const approveLink = Common.approveDetailUrl(params.projectCategoryId, params.dataId);
  const canApprove = Common.canApprove(selectedProjectCategory?.id, user);

  return (
    <>
      {canApprove > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
        selectedProjectCategory?.category_code == Config.CATEGORY_CODE_APPROVAL &&
          <li className={`${Config.tab(props.isType) == Config.Tab.Approve ? "uk-active" : ""}`}>
              <Link to={approveLink} className={approveLink === "" ? "disabled-link" : ""}>承認</Link>
          </li>
      }
      {canFile > Config.RESTRICTION_HISTORY_FILE_HIDE.value &&
          <li className={`${Config.tab(props.isType) == Config.Tab.File ? "uk-active" : ""}`}>
              <Link to={fileLink} className={fileLink === "" ? "disabled-link" : ""}>ファイル</Link>
          </li>
      }
    </>
  )
}