import {useSearchBasicMap} from "@/components/Attributes/BasicMap/useSearchBasicMap";
import {useProjectStore} from "@/stores/project";
import * as Config from "@/config";
import {basicMapStore} from "@/stores/basicMap";
import React, {Fragment, useMemo} from "react";
import * as Attributes from "@/components/Form/Attributes";

export const SearchRows = ({attributes}: { attributes: Array<any> }) => {
  const {basicMapSearchItem} = useSearchBasicMap();
  const selectedProjectCategory = useProjectStore(state => state.projectCategory);
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const {updatedCityStatus, submissionStatus, certificationStatus} = basicMapStore();
  const currentAttributes = useMemo(() => {
    return isBasicMap ? basicMapSearchItem(attributes, {
      updatedCityStatus,
      submissionStatus,
      certificationStatus
    }) : attributes;
  }, [isBasicMap, attributes, updatedCityStatus, submissionStatus, certificationStatus]);
  return (
    <>
      {currentAttributes.map((_: any, index: number) => (
        <Fragment key={`current-attribute-${index}`}>
          {index % 4 === 0 && (
            <tr className="header">
              {currentAttributes.slice(index, index + 4).map((attr: any) => (
                <td className="uk-width-1-4" key={`searchBox_td_1_${attr.id}`}>{attr.name}</td>
              ))}
              {/* 埋める */}
              {index + 4 >= currentAttributes.length &&
                Array.from({length: 4 - currentAttributes.slice(index, index + 4).length}).map((_, idx) => (
                  <td className="uk-width-1-4" key={`empty_td_1_${index + idx}`}></td>
                ))}
            </tr>
          )}
          {index % 4 === 0 && (
            <tr>
              {currentAttributes.slice(index, index + 4).map((attr: any) => (
                <td key={`searchBox_td_2_${attr.id}`}>{chooseComponent(attr)}</td>
              ))}
              {/* 埋める */}
              {index + 4 >= currentAttributes.length &&
                Array.from({length: 4 - currentAttributes.slice(index, index + 4).length}).map((_, idx) => (
                  <td key={`empty_td_2_${index + idx}`}></td>
                ))}
            </tr>
          )}
        </Fragment>
      ))}
    </>
  )
}

export const chooseComponent = (attribute: any) => {
  if (attribute.isStatus) {
    return <Attributes.BasicMapStatus name={attribute.isStatus} options={attribute.options}/>
  }
  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_FIELD:
    case Config.ATTRIBUTE_KINDS_CODE_TEXT_AREA:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_INT:
    case Config.ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL:
      return <Attributes.InputText attribute={attribute}/>;
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      const [parentName, filterName] = Attributes.hasRelation(attribute);
      if (parentName && filterName)
        return <Attributes.HasRelation attribute={attribute} parentName={parentName} filterName={filterName}/>;
      else
        return <Attributes.NoneRelation attribute={attribute}/>;
    case Config.ATTRIBUTE_KINDS_CODE_DATE:
      return <Attributes.InputText attribute={attribute} props={{type: 'date'}}/>;
      case Config.ATTRIBUTE_KINDS_CODE_YEAR_MONTH:
      return <Attributes.InputText attribute={attribute} props={{type: 'month'}}/>;
  }
}
