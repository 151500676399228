import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import MessageList from '../../../components/Ticket/MessageList';
import { Faq, Option, TicketComment } from "@/containers/data/Public/Public";
import { useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLoadingStore } from "@/stores/loading";
import { postFaqInformation } from "@/api/faqs";
import * as Common from "@/utils/common";
import { MESSAGE_NO_E08, MESSAGE_NO_E35, MESSAGE_NO_E37, MESSAGE_NO_E39 } from "@/config";

interface EditProps {
  faq: Faq
  faqStatuses: Option[]
  faqTypes: Option[]
  faqKinds: Option[]
  ticketComments: TicketComment[]
  setModalMessage: ( res: { type: string, message: string } | null ) => void
}

export interface PostFaqData {
  data_id: number
  data_faq_id?: number
  faq_statuses_id: number
  faq_types: { id: number, checked: boolean }[]
  faq_kinds: { id: number, checked: boolean }[]
  title: string
  question: string
  answer: string
  remarks: string
}

export interface PostFaqDataMethods {
  submit: () => Promise<any>
}

export const PublicEditItem = forwardRef<PostFaqDataMethods, EditProps>(( props, ref ) => {
  useImperativeHandle(ref, () => ({
    submit
  }));
  const setLoading = useLoadingStore(state => state.setLoading)
  const params = useParams();
  const { faq, faqStatuses, faqTypes, faqKinds, ticketComments, setModalMessage } = props
  const [errorMessage, setErrorMessage] = useState<string>("");

  const dataId = Number(params.dataId)
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<PostFaqData>({
    defaultValues: {
      data_id: dataId,
      data_faq_id: faq.id ?? undefined,
      faq_statuses_id: faq.faq_status_id,
      faq_types: faq.faq_types,
      faq_kinds: faq.faq_kinds,
      title: faq.title,
      question: faq.question,
      answer: faq.answer,
      remarks: faq.remarks
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  })

  const selectedFaqStatus = useWatch({
    control,
    name: "faq_statuses_id"
  })

  const post = ( data: PostFaqData ) => {
    console.log(data)
    setErrorMessage("")
    setLoading(true)
    return postFaqInformation(data).then(result => {
      console.log(result.data)
      const status = result.data.status
      const errors = result.data.errors;
      if (status === 1) {
        return true;
      } else if (status === -1) {
        setErrorMessage("入力エラーがあります");
        if (errors.title) setError("title", { type: "server", message: errors.title })
        if (errors.question) setError("question", { type: "server", message: errors.question })
        if (errors.answer) setError("answer", { type: "server", message: errors.answer })
        if (errors.remarks) setError("remarks", { type: "server", message: errors.remarks })
        if (errors.faq_types) setError("faq_types", { type: "server", message: errors.faq_types })
        if (errors.faq_kinds) setError("faq_kinds", { type: "server", message: errors.faq_kinds })
        if (errors.faq_statuses_id) setError("faq_statuses_id", { type: "server", message: errors.faq_statuses_id })
        return false;
      } else {
        setModalMessage({ type: "server", message: MESSAGE_NO_E37 })
        return false;
      }
    }).catch(error => {
      console.log(error)
      setModalMessage({ type: "server", message: MESSAGE_NO_E37 })
      return false;
    }).finally(() => {
      setLoading(false)
    })
  }

  async function submit() {
    clearErrors();
    const data = getValues();
    const isValid = await trigger();
    const hasSelectedFaqType = data.faq_types.some(type => type.checked);
    const hasSelectedFaqKind = data.faq_kinds.some(kind => kind.checked);

    if (!hasSelectedFaqType) {
      setError("faq_types", {
        type: "required",
        message: MESSAGE_NO_E08,
      });
    }

    if (!hasSelectedFaqKind) {
      setError("faq_kinds", {
        type: "required",
        message: MESSAGE_NO_E08,
      });
    }
    if (isValid && hasSelectedFaqType && hasSelectedFaqKind) {
      return await post(data);
    } else {
      setErrorMessage("入力エラーがあります");
      return false;
    }
  }

  return (
    <>
      {errorMessage.length > 0 && <div className="error-box">{errorMessage}</div>}
      <div className="info-tbl-wrap uk-margin-top mb-40">
        <form>
          <div className="info-tbl">
            <table className="uk-width-1-2">
              <tbody>
              <tr>
                <td className="label">ステータス</td>
                <td className="content">
                  <select
                    className={`uk-width-1-1 ${selectedFaqStatus ? '' : 'placeholder'} ${errors.faq_statuses_id ? "error-form" : ""}`}
                    defaultValue={""}
                    {...register(`faq_statuses_id`, { required: MESSAGE_NO_E08 })}
                  >
                    <option value="" disabled>選択してください</option>
                    {faqStatuses.map(( o ) => (<option key={o.id} value={o.id}>{o.name}</option>))}
                  </select>
                  {errors.faq_statuses_id && <div className="error">{errors.faq_statuses_id.message}</div>}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="info-tbl">
            <table className="uk-width-1-1">
              <tbody>
              <tr>
                <td className="label">情報区分</td>
                <td className="content">
                  <div className="uk-grid uk-grid-small">
                    {faqTypes?.map(( option, index ) => (
                      <label key={option.id}>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={faq.faq_types?.find(type => type.id === option.id)?.checked}
                            {...register(`faq_types.${index}.checked`)}
                          />
                        </div>
                        {option.name}
                      </label>
                    ))}
                  </div>
                  {errors.faq_types && <div className="error">{errors.faq_types.message}</div>}
                </td>
                <td className="label">情報種別</td>
                <td className="content">
                  <div className="uk-grid uk-grid-small">
                    {faqKinds?.map(( option, index ) => (
                      <label key={option.id}>
                        <div>
                          <input
                            type="checkbox"
                            defaultChecked={faq.faq_kinds?.find(kind => kind.id === option.id)?.checked}
                            // checked={kind.checked}
                            {...register(`faq_kinds.${index}.checked`)}
                          />
                        </div>
                        {option.name}
                      </label>
                    ))}
                  </div>
                  {errors.faq_kinds && <div className="error">{errors.faq_kinds.message}</div>}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="info-tbl">
            <table className="uk-width-1-1">
              <tbody>
              <tr>
                <td className="label">管理番号</td>
                <td>
                  {faq.id && String(faq.id).padStart(5, '0')}
                </td>
              </tr>
              <tr>
                <td className="label">公開タイトル</td>
                <td colSpan={3}>
                  <input
                    className="uk-width-1-1"
                    {...register(
                      "title",
                      { required: MESSAGE_NO_E08, maxLength: { value: 100, message: MESSAGE_NO_E35(100) }, }
                    )}/>
                  {errors.title && <div className="error">{errors.title.message}</div>}
                </td>
              </tr>
              </tbody>
              <tbody>
              <tr>
                <td className="label">公開質問</td>
                <td colSpan={3}>
                  <textarea
                    className="uk-width-1-1 h-87"
                    {...register(
                      "question",
                      { required: MESSAGE_NO_E08, maxLength: { value: 1000, message: MESSAGE_NO_E35(1000) }, }
                    )}/>
                  {errors.question && <div className="error">{errors.question.message}</div>}
                </td>
              </tr>
              </tbody>
              <tbody>
              <tr>
                <td className="label">公開内容</td>
                <td colSpan={3}>
                  <textarea
                    className="uk-width-1-1 h-87"
                    {...register(
                      "answer",
                      { required: MESSAGE_NO_E08, maxLength: { value: 2000, message: MESSAGE_NO_E35(2000) }, }
                    )}/>
                  {errors.answer && <div className="error">{errors.answer.message}</div>}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="info-tbl">
            <table className="uk-width-1-1">
              <tbody>
              <tr>
                <td className="label">備考</td>
                <td colSpan={3}>
                  <textarea
                    className="uk-width-1-1 h-87"
                    {...register(
                      "remarks",
                      { maxLength: { value: 500, message: MESSAGE_NO_E35(500) }, }
                    )}/>
                  {errors.remarks && <div className="error">{errors.remarks.message}</div>}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
        <div className="uk-margin-top">
          <MessageList ticketComments={ticketComments}/>
        </div>
      </div>
    </>
  );
})